import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from './store/slice/products';
import { fetchWarranties } from "./store/slice/warranties";
import { fetchTermsConditions } from './store/slice/termsConditions';
import { fetchUserManual } from './store/slice/userManual';
import { routes } from "./utils/Router";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Container, makeStyles } from '@material-ui/core';
import Appbar from "./component/navigation/Appbar";
import Dashboard from "./component/dashboard/Dashboard";
import ProductRoute from "./route/ProductRoute";
import WarrantyRoute from "./route/WarrantyRoute";
import TermsConditionsRoute from "./route/TermsConditionsRoute";
import UserManualRoute from "./route/UserManualRoute";
import Error404 from "./Error404";
import LoaderModule from "./component/LoaderModule";

const useStyles = makeStyles(() => ({
    container: {
        marginTop: "64px",
        "@media (max-width: 599px)": {
            marginTop: "56px",
        },
    },
}));

function Base() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const statusProducts = useSelector((state) => state.products.status);
    const statusWarranties = useSelector((state) => state.warranties.status);
    const statusTermsConditions = useSelector((state) => state.termsConditions.status);
    const statusUserManual = useSelector((state) => state.userManual.status);

    let { path } = useRouteMatch();

    useEffect(() => {
        if (statusProducts === 'idle') {
            dispatch(fetchProducts());
        }
        if (statusWarranties === 'idle') {
            dispatch(fetchWarranties());
        }
        if (statusTermsConditions === 'idle') {
            dispatch(fetchTermsConditions());
        }
        if (statusUserManual === 'idle') {
            dispatch(fetchUserManual());
        }
    });

    if (statusProducts === 'succeeded' && statusWarranties === 'succeeded' && statusTermsConditions === 'succeeded' && statusUserManual === 'succeeded') {
        return (
            <div>
                <Appbar />
                <Container className={classes.container}>
                    <Switch>
                        <Route
                            exact
                            path={path}
                            render={({ location }) =>
                                <Redirect
                                    to={{
                                        pathname: `${path}${routes.dashboard.partial}`,
                                        state: { from: location }
                                    }}
                                />
                            }
                        />
                        <Route path={`${path}${routes.dashboard.partial}`}>
                            <Dashboard />
                        </Route>
                        <Route path={`${path}${routes.products.partial}`}>
                            <ProductRoute />
                        </Route>
                        <Route path={`${path}${routes.warranties.partial}`}>
                            <WarrantyRoute />
                        </Route>
                        <Route path={`${path}${routes.termsConditions.partial}`}>
                            <TermsConditionsRoute />
                        </Route>
                        <Route path={`${path}${routes.userManual.partial}`}>
                            <UserManualRoute />
                        </Route>
                        <Route path={`${path}/*`}>
                            <Error404 />
                        </Route>
                    </Switch>
                </Container>
            </div>
        );
    } else {
        return <LoaderModule />;
    }
}

export default Base;