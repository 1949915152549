import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../store/store';
import { createWarranty } from '../../store/slice/warranties';
import { selectAllProducts } from '../../store/slice/products';
import { selectAllUserManual } from '../../store/slice/userManual';
import { selectAllTermsConditions } from '../../store/slice/termsConditions';
import { useHistory } from 'react-router';
import { routes } from '../../utils/Router';
import { useForm } from '../../hook/useForm';
import { unwrapResult } from '@reduxjs/toolkit';
import { requestStatus } from '../../utils/RequestEnum';
import { useSnackbar } from 'notistack';
import { Paper, Grid, Box, Typography, Button, makeStyles } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import Form from '../form/Form';
import FormInput from '../form/FormInput';
import FormSelect from '../form/FormSelect';
import FormButton from '../form/FormButton';
import ProductIcon from '../../assets/icon/ProductIcon';
import { palette_000, palette_600, palette_800 } from '../../utils/Colors';

const useStyles = makeStyles((theme) => ({
    iconPaper: {
        display: "flex",
        margin: "0 auto",
        alignItems: "center",
        width: 110,
        height: 110,
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    icon: {
        margin: "0 auto",
        width: 70,
        height: 70,
        fill: palette_600
    },
    imagePaper: {
        maxWidth: "120px",
        maxHeight: "120px",
        margin: "0 auto",
        padding: "10px 0px",
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    image: {
        maxWidth: "100px",
        maxHeight: "100px",
        margin: "0 auto",
        padding: "0px"
    },
    paper: {
        margin: "0 auto",
        padding: "0px",
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    title: {
        padding: "5px 5px",
        color: palette_600,
        fontSize: "20px",
        fontWeight: 600
    },
    subtitle: {
        padding: "0px 5px 5px 5px",
        color: palette_800,
        fontSize: "16px"
    },
    divider: {
        padding: "0px 5px"
    },
}));

const FileAlert = (props) => {
    return <Alert elevation={6} variant="filled" {...props} />;
}

const WarrantyCreate = () => {
    const classes = useStyles();

    const products = useSelector((state) => selectAllProducts(state));
    const userManuals = useSelector((state) => selectAllUserManual(state));
    const termsConditions = useSelector((state) => selectAllTermsConditions(state));

    const dispatch = useDispatch();
    const history = useHistory();

    const [status, setStatus] = useState(requestStatus.IDLE);

    const { enqueueSnackbar } = useSnackbar();

    const {
        values,
        errors,
        setInputChange,
        setMultipleInputChanges,
        handleInputChange
    } = useForm({
        image: "",
        productId: 0,
        productSerialNumber: [''],
        userManualId: 0,
        termsConditionsId: 0,
        durationMonths: 0
    });

    const handleArray = async e => {
        e.preventDefault();
        const [ name, index ] = e.target.name.split("_");
        let changes = Array.from(values[name]);
        changes[index] = e.target.value;
        setInputChange(name, changes);
    }

    const handleProduct = async e => {
        e.preventDefault();
        setMultipleInputChanges({
            [e.target.name]: e.target.value,
            "image": store.getState().products.entities[e.target.value].image
        });
    }

    const handleCreate = async e => {
        e.preventDefault();
        try {
            setStatus(requestStatus.LOADING);
            const resultAction = await dispatch(createWarranty({ ...values }));
            unwrapResult(resultAction);
            enqueueSnackbar(`Welldone`, {
                variant: 'success',
            });
            setStatus(requestStatus.SUCCEEDED);
            history.push(routes.warranties.full);
        } catch (err) {
            enqueueSnackbar(`Invalid`, {
                variant: 'error',
            });
            setStatus(requestStatus.FAILED);
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Form>
                <Grid item xs={12}><Box p={1} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper
                        elevation={3}
                        className={values.image !== "" ? classes.imagePaper : classes.iconPaper}
                    >
                        <Box className={values.image !== "" ? classes.image : classes.icon}>
                            {
                                values.image !== "" ? <img src={values.image} alt="Product" height={100} /> : <ProductIcon />
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <FileAlert severity="info">Select Product Id to add an image</FileAlert>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Box display="flex">
                        <Box flexGrow={1} p={1}>
                            <Typography variant="button">Products Number</Typography>
                        </Box>
                        <Box>
                            <Button
                                aria-label="reduce"
                                onClick={() => {
                                    if (values.productSerialNumber.length > 1) {
                                        let changes = values.productSerialNumber;
                                        changes.pop();
                                        setInputChange("productSerialNumber", changes);
                                    }
                                }}
                            >
                                <Remove fontSize="small" />
                            </Button>
                            <Typography variant="button">{values.productSerialNumber.length}</Typography>
                            <Button
                                aria-label="increase"
                                onClick={() => {
                                    let changes = values.productSerialNumber;
                                    changes.push('');
                                    setInputChange("productSerialNumber", changes);
                                }}
                            >
                                <Add fontSize="small" />
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                {
                    values.productSerialNumber.map((_, index) => (
                        <Grid key={`productSerialNumber_${index}`}>
                            <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                            <Grid item xs={12}>
                                <Paper elevation={3} className={classes.paper}>
                                    <Box flexGrow={1} p={1}>
                                        <Box className={classes.title}>
                                            <FormInput
                                                label="Product Serial Number"
                                                name={`productSerialNumber_${index}`}
                                                value={values.productSerialNumber[index]}
                                                error={errors[`productSerialNumber_${index}`]}
                                                onChange={handleArray}
                                                required={true}
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    ))
                }
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>
                                <FormSelect
                                    label="Product"
                                    name="productId"
                                    value={values.productId}
                                    error={errors.productId}
                                    onChange={handleProduct}
                                    objectKey={"model"}
                                    data={products}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>
                                <FormSelect
                                    label="User Manual"
                                    name="userManualId"
                                    value={values.userManualId}
                                    error={errors.userManualId}
                                    onChange={handleInputChange}
                                    objectKey={"name"}
                                    data={userManuals}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>
                                <FormSelect
                                    label="Terms & Conditions"
                                    name="termsConditionsId"
                                    value={values.termsConditionsId}
                                    error={errors.termsConditionsId}
                                    onChange={handleInputChange}
                                    objectKey={"name"}
                                    data={termsConditions}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>
                                <FormInput
                                    type="number"
                                    label="Duration Months"
                                    name="durationMonths"
                                    value={values.durationMonths}
                                    error={errors.durationMonths}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <FormButton
                        status={status}
                        label="Create"
                        onSubmit={handleCreate}
                    />
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
            </Form>
        </Grid>
    );
}

export default WarrantyCreate;