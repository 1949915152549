import React from 'react';
import { makeStyles } from '@material-ui/core';
import { palette_150, palette_600, } from "./../../utils/Colors";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
        '& label.Mui-focused': {
            color: palette_600,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette_600,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: palette_150,
            },
            '&:hover fieldset': {
                borderColor: palette_600,
            },
            '&.Mui-focused fieldset': {
                borderColor: palette_600,
            },
        }
    }
}));

export default function Form(props) {
    const classes = useStyles();
    const { children, ...other } = props;
    return (
        <form className={classes.form} noValidate autoComplete="off" {...other}>
            {props.children}
        </form>
    )
};