import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Paper, Box, Divider, makeStyles } from '@material-ui/core';
import SearchBoxSelect from './SearchBoxSelect';
import SearchBoxInput from './SearchBoxInput';
import SearchBoxFilter from './SearchBoxFilter';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '2px 4px',
        width: "100%"
    },
    box: {
        alignItems: "center",
        width: "100%"
    },
    divider: {
        height: 30,
    },
}));

const SearchBox = forwardRef((props, ref) => {
    const classes = useStyles();

    const typeRef = useRef(null);
    const valueRef = useRef(null);
    const filterRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            type() {
                return typeRef.current.value();
            },
            value() {
                return valueRef.current.value();
            },
            filter() {
                return filterRef.current.value();
            }
        })
    );

    return (
        <Box p={1}>
            <Paper component="form" className={classes.root}>
                <Box display="flex" className={classes.box}>
                    <Box p={1}>
                        <SearchBoxSelect 
                            ref={typeRef}
                            options={props.options}
                        />
                    </Box>
                    <SearchBoxInput ref={valueRef} onChange={(value) => props.onValueChange(value)} />
                    <Box p={1}>
                        <Divider className={classes.divider} orientation="vertical" />
                    </Box>
                    <Box p={1}>
                        <SearchBoxFilter ref={filterRef} filters={props.filters} onChange={(filter) => props.onFilterChange(filter)} />
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
});

SearchBox.propTypes = {
    options: PropTypes.array.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired
};

export default SearchBox;
