import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slice/user";
import productsSlice from "./slice/products";
import warrantiesSlice from "./slice/warranties";
import termsConditionsSlice from "./slice/termsConditions";
import userManualSlice from "./slice/userManual";

const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        products: productsSlice.reducer,
        warranties: warrantiesSlice.reducer,
        termsConditions: termsConditionsSlice.reducer,
        userManual: userManualSlice.reducer,
    }
});

export default store;