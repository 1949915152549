import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
} from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { requestStatus } from "./../../utils/RequestEnum";

const userManualAdapter = createEntityAdapter({
    sortComparer: (a, b) => b.dateCreated.localeCompare(a.dateCreated),
});

const initialState = userManualAdapter.getInitialState({
    status: requestStatus.IDLE,
    error: null,
});

export const fetchUserManual = createAsyncThunk('/client/userManual/fetch', async () => {
    const response = await client.get('userManuals');
    return response.objects;
});

export const createUserManual = createAsyncThunk(
    '/client/userManual/create',
    async (data) => {
        const response = await client.post('userManuals', { body: { ...data } });
        return response.object;
    }
);

export const updateUserManual = createAsyncThunk(
    '/client/userManual/update',
    async ({ id, ...changes }) => {
        const response = await client.put(`userManuals/${id}`, { body: { ...changes } });
        return response.object;
    }
);

export const deleteUserManual = createAsyncThunk(
    '/client/userManual/delete',
    async ({ id }) => {
        await client.delete(`userManuals/${id}`);
        return { id: id };
    }
);

const userManualSlice = createSlice({
    name: 'userManual',
    initialState: initialState,
    reducers: {
        userManualCreated(state, action) {
            const { ...data } = action.payload;
            userManualAdapter.upsertOne(state, data);
        },
        userManualUpdated(state, action) {
            const { id, ...changes } = action.payload;
            userManualAdapter.updateOne(state, { id, changes });
        },
        userManualDeleted(state, action) {
            const { id } = action.payload;
            userManualAdapter.removeOne(state, id);
        },
    },
    extraReducers: {
        // Fetch
        [fetchUserManual.pending]: (state) => {
            state.status = requestStatus.LOADING;
        },
        [fetchUserManual.fulfilled]: (state, action) => {
            state.status = requestStatus.SUCCEEDED;
            userManualAdapter.upsertMany(state, action.payload);
        },
        [fetchUserManual.rejected]: (state, action) => {
            state.status = requestStatus.FAILED;
            state.error = action.payload;
        },
        // Create
        [createUserManual.fulfilled]: (state, action) => {
            userManualSlice.caseReducers.userManualCreated(state, action);
        },
        [createUserManual.rejected]: (state, action) => {
            state.error = action.payload;
        },
        // Update
        [updateUserManual.fulfilled]: (state, action) => {
            userManualSlice.caseReducers.userManualUpdated(state, action);
        },
        [updateUserManual.rejected]: (state, action) => {
            state.error = action.payload;
        },
        // Delete
        [deleteUserManual.fulfilled]: (state, action) => {
            userManualSlice.caseReducers.userManualDeleted(state, action);
        },
        [deleteUserManual.rejected]: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const { userManualCreated, userManualUpdated, userManualDeleted } = userManualSlice.actions;

export default userManualSlice;

export const {
    selectAll: selectAllUserManual,
    selectById: selectUserManualById,
    selectIds: selectUserManualIds,
} = userManualAdapter.getSelectors((state) => state.userManual);

export const selectUserManualByBrand = createSelector(
    [selectAllUserManual, (state, brand) => brand],
    (storage, brand) => storage.filter((entity) => entity.brand === brand)
);

export const selectUserManualIdsByFilter = createSelector(
    [selectAllUserManual, (state, settings) => settings],
    (storage, settings) => {
        switch (settings.filter) {
            case 'DATE_DESC': {
                storage.sort((a, b) => b.dateCreated.localeCompare(a.dateCreated));
                return selectUserManualIdsByFilterKeys(storage, settings);
            }
            case 'DATE_ASC': {
                storage.sort((a, b) => a.dateCreated.localeCompare(b.dateCreated));
                return selectUserManualIdsByFilterKeys(storage, settings);
            }
            default: return storage.map(entity => entity.id);
        }
    }
);

const selectUserManualIdsByFilterKeys = (storage, settings) => {
    switch (settings.key) {
        case 'ALL':
            return storage.map(entity => entity.id);
        case 'FILTER_BY_NAME':
            return storage.filter((entity) => entity.name.toLowerCase().includes(settings.value.toLowerCase())).map(entity => entity.id);
        case 'FILTER_BY_BRAND':
            return storage.filter((entity) => entity.brand.toLowerCase().includes(settings.value.toLowerCase())).map(entity => entity.id);
        default:
            return storage.map(entity => entity.id);
    }
}