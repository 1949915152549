import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { requestStatus } from "./../../utils/RequestEnum";

const warrantiesAdapter = createEntityAdapter({
    sortComparer: (a, b) => b.dateCreated.localeCompare(a.dateCreated),
});

const initialState = warrantiesAdapter.getInitialState({
    status: requestStatus.IDLE,
    error: null,
});

export const fetchWarranties = createAsyncThunk('/client/warranties/fetch', async () => {
    const response = await client.get('warranties');
    return response.objects;
});

export const createWarranty = createAsyncThunk(
    '/client/warranties/create',
    async (data) => {
        const response = await client.post('warranties', { body: { ...data } });
        return response.objects;
    }
);

export const updateWarranty = createAsyncThunk(
    '/client/warranties/update',
    async ({ id, ...changes }) => {
        const response = await client.put(`warranties/${id}`, { body: { ...changes } });
        return response.object;
    }
);

export const deleteWarranty = createAsyncThunk(
    '/client/warranties/delete',
    async ({ id }) => {
        await client.delete(`warranties/${id}`);
        return { id: id };
    }
);

const warrantiesSlice = createSlice({
    name: 'warranties',
    initialState: initialState,
    reducers: {
        warrantyCreated(state, action) {
            const { ...data } = action.payload;
            warrantiesAdapter.upsertMany(state, data);
        },
        warrantyUpdated(state, action) {
            const { id, ...changes } = action.payload;
            warrantiesAdapter.updateOne(state, { id, changes });
        },
        warrantyDeleted(state, action) {
            const { id } = action.payload;
            warrantiesAdapter.removeOne(state, id);
        },
    },
    extraReducers: {
        // Fetch
        [fetchWarranties.pending]: (state) => {
            state.status = requestStatus.LOADING;
        },
        [fetchWarranties.fulfilled]: (state, action) => {
            state.status = requestStatus.SUCCEEDED;
            warrantiesAdapter.upsertMany(state, action.payload);
        },
        [fetchWarranties.rejected]: (state, action) => {
            state.status = requestStatus.FAILED;
            state.error = action.payload;
        },
        // Create
        [createWarranty.fulfilled]: (state, action) => {
            warrantiesSlice.caseReducers.warrantyCreated(state, action);
        },
        [createWarranty.rejected]: (state, action) => {
            state.error = action.payload;
        },
        // Update
        [updateWarranty.fulfilled]: (state, action) => {
            warrantiesSlice.caseReducers.warrantyUpdated(state, action);
        },
        [updateWarranty.rejected]: (state, action) => {
            state.error = action.payload;
        },
        // Delete
        [deleteWarranty.fulfilled]: (state, action) => {
            warrantiesSlice.caseReducers.warrantyDeleted(state, action);
        },
        [deleteWarranty.rejected]: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const { warrantyCreated, warrantyUpdated, warrantyDeleted } = warrantiesSlice.actions;

export default warrantiesSlice;

export const {
    selectAll: selectAllWarranties,
    selectById: selectWarrantyById,
    selectIds: selectWarrantiesIds,
} = warrantiesAdapter.getSelectors((state) => state.warranties);

export const selectWarrantiesIdsByFilter = createSelector(
    [selectAllWarranties, (state, settings) => settings],
    (storage, settings) => {
        switch (settings.filter) {
            case 'DATE_DESC': {
                storage.sort((a, b) => b.dateCreated.localeCompare(a.dateCreated));
                return selectWarrantiesIdsByFilterKeys(storage, settings);
            }
            case 'DATE_ASC': {
                storage.sort((a, b) => a.dateCreated.localeCompare(b.dateCreated));
                return selectWarrantiesIdsByFilterKeys(storage, settings);
            }
            case 'EXPIRED': {
                return selectWarrantiesIdsByFilterKeys(storage.filter((entity) => parseInt(entity.status) === -1), settings);
            }
            case 'PENDING': {
                return selectWarrantiesIdsByFilterKeys(storage.filter((entity) => parseInt(entity.status) === 0), settings);
            }
            case 'ACTIVATED': {
                return selectWarrantiesIdsByFilterKeys(storage.filter((entity) => parseInt(entity.status) === 1), settings);
            }
            case 'ISSUED': {
                return selectWarrantiesIdsByFilterKeys(storage.filter((entity) => parseInt(entity.status) === 2), settings);
            }
            default: return storage.map(entity => entity.id);
        }
    }
);

const selectWarrantiesIdsByFilterKeys = (storage, settings) => {
    switch (settings.key) {
        case 'ALL':
            return storage.map(entity => entity.id);
        case 'FILTER_BY_WID':
            return storage.filter((entity) => entity.id.toLowerCase().includes(settings.value.toLowerCase())).map(entity => entity.id);
        case 'FILTER_BY_PSN':
            return storage.filter((entity) => entity.productSerialNumber.toLowerCase().includes(settings.value.toLowerCase())).map(entity => entity.id);
        default: 
            return storage.map(entity => entity.id);
    }
}
