import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    updateTermsConditions,
    deleteTermsConditions,
} from '../../store/slice/termsConditions';
import { useHistory } from 'react-router';
import { routes } from '../../utils/Router';
import { useForm } from '../../hook/useForm';
import { unwrapResult } from '@reduxjs/toolkit';
import { requestStatus } from '../../utils/RequestEnum';
import { useSnackbar } from 'notistack';
import { Paper, Grid, Box, Button, Divider, withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FileCopyOutlined, EditOutlined, RestoreOutlined, DeleteOutline } from '@material-ui/icons';
import Form from '../form/Form';
import FormInput from '../form/FormInput';
import FormButton from '../form/FormButton';
import PdfIcon from '../../assets/icon/PdfIcon';
import { palette_600, palette_danger } from '../../utils/Colors';

const FileAlert = (props) => {
    return <Alert elevation={6} variant="filled" {...props} />;
}

const FileButton = withStyles((theme) => ({
    root: {
        color: palette_600,
        borderColor: palette_600
    },
}))(Button);

const EditButton = withStyles((theme) => ({
    root: {
        color: palette_600,
        borderColor: palette_600
    },
}))(Button);

const ResetButton = withStyles((theme) => ({
    root: {
        color: palette_600,
        borderColor: palette_600
    },
}))(Button);

const DeleteButton = withStyles((theme) => ({
    root: {
        color: palette_danger,
        borderColor: palette_danger
    },
}))(Button);

const TermsConditionsPageInit = (props) => {
    const classes = props.classes;
    const termsConditions = props.termsConditions;

    const [status, setStatus] = useState(requestStatus.IDLE);

    const dispatch = useDispatch();
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const {
        mode,
        values,
        errors,
        toggleMode,
        setInputChange,
        handleInputChange,
        resetForm
    } = useForm({
        name: termsConditions.name,
        brand: termsConditions.brand,
        file: termsConditions.file
    });

    const fileInputRef = useRef(null);

    const handleFile = e => {
        e.preventDefault();
        try {
            let reader = new FileReader();

            reader.onload = function (e) {
                setInputChange("file", e.target.result);
            };

            reader.readAsDataURL(e.target.files[0]);
        } catch (e) {
            enqueueSnackbar(`Invalid`, { 
                variant: 'error',
            });
        }
    };

    const handleEdit = e => {
        e.preventDefault();
        toggleMode();
    };

    const handleReset = e => {
        e.preventDefault();
        resetForm();
    };

    const handleUpdate = async e => {
        e.preventDefault();
        try {
            setStatus(requestStatus.LOADING);
            const resultAction = await dispatch(updateTermsConditions({ id: termsConditions.id, ...values }));
            unwrapResult(resultAction);
            enqueueSnackbar(`Welldone`, { 
                variant: 'success',
            });
            setStatus(requestStatus.SUCCEEDED);
            toggleMode();
        } catch (err) {
            enqueueSnackbar(`Invalid`, { 
                variant: 'error',
            });
            setStatus(requestStatus.FAILED);
        }
    };

    const handleDelete = e => {
        e.preventDefault();
        dispatch(deleteTermsConditions({ id: termsConditions.id }));
        history.push(routes.termsConditions.full);
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Form>
                <Grid item xs={12}><Box p={1} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper
                        elevation={3}
                        className={classes.iconPaper}
                        onClick={() => { if (mode) fileInputRef.current.click() }}
                        style={mode ? { cursor: "pointer" } : { cursor: "default" }}
                    >
                        <Box className={classes.icon}>
                            <PdfIcon />
                        </Box>
                    </Paper>
                    <input ref={fileInputRef} id="pdf" accept="application/pdf" type="file" onChange={handleFile} hidden />
                </Grid>
                {
                    mode && (
                        <>
                            <Grid item xs={12}><Box p={1} /></Grid> {/* Divider */}
                            <Grid item xs={12}>
                                <FileAlert severity="info">Click the PDF Icon to add a file</FileAlert>
                            </Grid>
                        </>
                    )
                }
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Box display="flex">
                        <Box flexGrow={1} p={1} />
                        <Box>
                            <FileButton
                                variant="outlined"
                                startIcon={<FileCopyOutlined />}
                                className={classes.tooltip}
                                onClick={() => window.open(values.file, '_blank')}
                                disabled={mode}
                            >
                                Read File
                            </FileButton>
                            {
                                mode ? (
                                    <ResetButton
                                        variant="outlined"
                                        startIcon={<RestoreOutlined />}
                                        className={classes.tooltip}
                                        onClick={handleReset}
                                    >
                                        Reset
                                    </ResetButton>
                                ) : (
                                    <EditButton
                                        variant="outlined"
                                        startIcon={<EditOutlined />}
                                        className={classes.tooltip}
                                        onClick={handleEdit}
                                    >
                                        Edit
                                    </EditButton>
                                )
                            }
                            <DeleteButton
                                variant="outlined"
                                startIcon={<DeleteOutline />}
                                className={classes.tooltip}
                                onClick={handleDelete}
                                disabled={mode}
                            >
                                Delete
                            </DeleteButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormInput
                                            label="Name"
                                            name="name"
                                            value={values.name}
                                            error={errors.name}
                                            onChange={handleInputChange}
                                            required={true}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box className={classes.title}>{values.name}</Box>
                                        <Box className={classes.subtitle}> Name </Box>
                                    </>
                                )
                            }
                            <Box className={classes.divider}><Divider /></Box>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormInput
                                            label="Brand"
                                            name="brand"
                                            value={values.brand}
                                            error={errors.brand}
                                            onChange={handleInputChange}
                                            required={true}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box className={classes.title}>{values.brand}</Box>
                                        <Box className={classes.subtitle}> Brand </Box>
                                    </>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>{termsConditions.dateCreated}</Box>
                            <Box className={classes.subtitle}> Created At </Box>
                            <Box className={classes.divider}><Divider /></Box>
                            <Box className={classes.title}>{termsConditions.dateUpdated}</Box>
                            <Box className={classes.subtitle}> Update At </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                {
                    mode && (
                        <>
                            <Grid item xs={12}>
                                <FormButton
                                    status={status}
                                    label="Update"
                                    onSubmit={handleUpdate}
                                />
                            </Grid>
                            <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                        </>
                    )
                }
            </Form>
        </Grid>
    );
}

TermsConditionsPageInit.propTypes = {
    classes: PropTypes.object.isRequired,
    termsConditions: PropTypes.object.isRequired
};

export default TermsConditionsPageInit;