import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { routes } from "./utils/Router";
import { Provider, useSelector } from "react-redux";
import { localStorage } from "es-storage";
import store from "./store/store";
import { selectUser, createUser, destroyUser, validate } from "./store/slice/user";
import { unwrapResult } from "@reduxjs/toolkit";
import { requestStatus } from "./utils/RequestEnum";
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import LoaderModule from "./component/LoaderModule";
import Base from "./Base";
import Auth from "./Auth";
import Error404 from "./Error404";
import './App.css';

function App() {
  const [status, setStatus] = useState(requestStatus.LOADING);

  useEffect(() => {
    async function fetchAuthState() {
      try {
        let authToken = await localStorage.get('authToken');
        if (authToken && await validate(authToken)) {
          const resultAction = await store.dispatch(
            createUser({ authToken: authToken })
          );
          unwrapResult(resultAction);
          setStatus(requestStatus.SUCCEEDED);
        } else {
          throw new Error('Invalid auth token');
        }
      } catch (err) {
        const resultAction = await store.dispatch(
          destroyUser()
        );
        unwrapResult(resultAction);
        setStatus(requestStatus.FAILED);
      }
    }
    if (status === requestStatus.LOADING) {
      fetchAuthState();
    }
  }, [status]);

  return (
    <Provider store={store}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        TransitionComponent={Slide}
      >
        <Router>
          <div>
            <Switch>
              <Route
                exact
                path="/"
                render={({ location }) =>
                  <Redirect
                    to={{
                      pathname: routes.client.full,
                      state: { from: location }
                    }}
                  />
                }
              />
              <PrivateRoute status={status} path={routes.client.full}>
                <Base />
              </PrivateRoute>
              <AuthRoute status={status} path={routes.auth.full}>
                <Auth />
              </AuthRoute>
              {/* <Route path={routes.public.full}>
                <PublicPage />
              </Route> */}
              <Route path="*">
                <Error404 />
              </Route>
            </Switch>
          </div>
        </Router>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;

function PrivateRoute({ children, status, ...rest }) {
  const user = useSelector(selectUser);
  if (status !== requestStatus.LOADING) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: routes.auth.full,
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  } else {
    return <LoaderModule />;
  }
}

function AuthRoute({ children, status, ...rest }) {
  const user = useSelector(selectUser);
  if (status !== requestStatus.LOADING) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          user ? (
            <Redirect
              to={{
                pathname: routes.client.full,
                state: { from: location }
              }}
            />
          ) : (
            children
          )
        }
      />
    );
  } else {
    return <LoaderModule />;
  }
}

// function PublicPage() {
//   return <h3>Public</h3>;
// }