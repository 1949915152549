import React from 'react';
import { Grid } from '@material-ui/core';
import { Switch, Route, useRouteMatch } from 'react-router';
import Header from '../component/navigation/Header';
import ProductsView from '../component/product/ProductsView';
import ProductCreate from '../component/product/ProductCreate';
import ProductPage from '../component/product/ProductPage';

function ProductRoute() {

    let { path } = useRouteMatch();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Header name={"Products"} />
            <Switch>
                <Route exact path={path}>
                    <ProductsView />
                </Route>
                <Route path={`${path}/create`}>
                    <ProductCreate />
                </Route>
                <Route path={`${path}/:productId`}>
                    <ProductPage />
                </Route>
            </Switch>
        </Grid>
    );
}

export default ProductRoute;