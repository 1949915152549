import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { localStorage } from "es-storage";
import { client } from "../../api/client";
import { requestStatus } from "./../../utils/RequestEnum";

export const signIn = createAsyncThunk(
    'auth/signIn',
    async (user) => {
        const response = await client.post('user/business/login', { body: { email: user.email, password: user.password }, useAuthToken: false });
        return { authToken: response.object.authToken };
    }
);

export const signUp = createAsyncThunk(
    'auth/signUp',
    async (user) => {
        const response = await client.post('user/business/register', { body: { ...user }, useAuthToken: false });
        return { authToken: response.object.authToken };
    }
);

export const signOut = createAsyncThunk(
    'auth/signOut',
    async () => {
        // const response = await client.post('/fakeApi/posts', { user: user });
        return null;
    }
);

export const validate = async (authToken) => {
    try {
        const response = await client.get(`validate/${authToken}/authToken/business`, { useAuthToken: false });
        return response.status;
    } catch (e) {
        return false;
    }
};

const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: null,
        status: requestStatus.IDLE,
        error: null,
    },
    reducers: {
        createUser: (state, action) => {
            const { authToken } = action.payload;
            localStorage.set('authToken', authToken);
            state.data = { authToken: authToken };
        },
        updateUser: (state, action) => {
            const { authToken } = action.payload;
            localStorage.set('authToken', authToken);
            state.data = { authToken: authToken };
        },
        destroyUser: (state) => {
            localStorage.remove('authToken');
            state.data = null;
        }
    },
    extraReducers: {
        // Sign In
        [signIn.pending]: (state) => {
            state.status = requestStatus.LOADING;
        },
        [signIn.fulfilled]: (state, action) => {
            state.status = requestStatus.SUCCEEDED;
            userSlice.caseReducers.updateUser(state, action);
        },
        [signIn.rejected]: (state, action) => {
            state.status = requestStatus.FAILED;
            state.error = action.payload;
        },
        // Sign Up
        [signUp.pending]: (state) => {
            state.status = requestStatus.LOADING;
        },
        [signUp.fulfilled]: (state, action) => {
            state.status = requestStatus.SUCCEEDED;
            userSlice.caseReducers.createUser(state, action);
        },
        [signUp.rejected]: (state, action) => {
            state.status = requestStatus.FAILED;
            state.error = action.payload;
        },
        // Sign Out
        [signOut.pending]: (state) => {
            state.status = requestStatus.LOADING;
        },
        [signOut.fulfilled]: (state) => {
            state.status = requestStatus.SUCCEEDED;
            userSlice.caseReducers.destroyUser(state);
        },
        [signOut.rejected]: (state, action) => {
            state.status = requestStatus.FAILED;
            state.error = action.payload;
        },
    }
});

export const { createUser, updateUser, destroyUser } = userSlice.actions;

export const selectUser = (state) => state.user.data;

export default userSlice;