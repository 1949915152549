import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%"
    }
}));

export default function FormInput(props) {
    const classes = useStyles();
    const { type = "text", label, name, value, error = null, onChange, ...other } = props;
    return (
        <TextField
            key={name}
            id={name}
            type={type}
            variant="outlined"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            {...(error && { error: true, helperText: error })}
            {...other}
            className={classes.field}
        />
    );
}