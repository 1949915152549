import { Grid, makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";
import warrantyboxLogo from "./../assets/logo/logo.svg";

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        height: "auto"
    },
    innerContainer: {
        margin: "0 auto",
        textAlign: "center"
    },
    logo: {
        display: "block",
        position: "relative",
        margin: "0 auto",
        maxWidth: "120px",
        cursor: "pointer"
    },
}));

export default function LoaderModule() {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.container}
            style={{ height: "100vh" }}
        >
            <Grid item xs={12} className={classes.innerContainer}>
                <motion.img
                    animate={{ opacity: [1, 0.6, 0.6, 1] }}
                    transition={{ ease: "easeIn", repeat: Infinity }}
                    src={warrantyboxLogo}
                    alt="Warrantybox Logo"
                    className={classes.logo}
                />
            </Grid>
        </Grid>
    );
}

export function LoaderModuleBox() {
    const classes = useStyles();

    const height = window.innerHeight - document.body.offsetHeight - 64;

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.container}
            style={{ height: height }}
        >
            <Grid item xs={12} className={classes.innerContainer}>
                <motion.img
                    animate={{ opacity: [1, 0.6, 0.6, 1] }}
                    transition={{ ease: "easeIn", repeat: Infinity }}
                    src={warrantyboxLogo}
                    alt="Warrantybox Logo"
                    className={classes.logo}
                />
            </Grid>
        </Grid>
    );
}