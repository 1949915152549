import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Paper, IconButton, Popper, Grow, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';

const SearchBoxFilter = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(props.filters[0].value);

    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleItemClick = (event, value) => {
        setValue(value);
        props.onChange(value);
        handleClose(event);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useImperativeHandle(
        ref,
        () => ({
            value() {
                return value;
            }
        })
    );

    return (
        <>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? 'filter-list-grow' : undefined}
                aria-haspopup="true"
                aria-label="search icon"
                onClick={handleToggle}
            >
                <FilterList />
            </IconButton>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="filter-list-grow" onKeyDown={handleListKeyDown}>
                                    {props.filters.map((filter) => (
                                        <MenuItem
                                            key={filter.label}
                                            selected={value === filter.value}
                                            onClick={(event) => handleItemClick(event, filter.value)}
                                        >
                                            {filter.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
});

SearchBoxFilter.propTypes = {
    filters: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

export default SearchBoxFilter;