import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserManualById } from '../../store/slice/userManual';
import { useHistory, useRouteMatch } from 'react-router';
import { Box, Grid, Paper, makeStyles } from "@material-ui/core";
import { palette_000, palette_600, palette_800, palette_900 } from '../../utils/Colors';
import PdfIcon from '../../assets/icon/PdfIcon';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "0px",
        cursor: "pointer",
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    content: {
        alignItems: "center"
    },
    icon: {
        display: "flex",
        alignItems: "center",
        width: 100,
        height: 100,
        boxShadow: "10px 0px 9px -5px rgb(206 206 206 / 50%)"
    },
    iconInner: {
        margin: "0 auto",
        width: 60,
        height: 60,
        fill: palette_600
    },
    name: {
        color: palette_900,
        padding: "5px 5px",
        fontSize: "18px",
        fontWeight: 600
    },
    brand: {
        color: palette_800,
        padding: "0px 5px",
        fontSize: "16px"
    },
    container: {
        textAlign: "center",
    },
    counter: {
        color: palette_600,
        fontSize: "20px",
        fontWeight: 500
    },
    title: {
        color: palette_800,
        fontSize: "14px",
        textTransform: "uppercase"
    },
}));

export const UserManualCard = ({ userManualId }) => {
    let history = useHistory();
    let { path } = useRouteMatch();

    const classes = useStyles();
    const userManual = useSelector((state) => selectUserManualById(state, userManualId));

    return (
        <Grid item xs={12}>
            <Box p={1}>
                <Paper elevation={3} className={classes.paper} onClick={() => history.push(`${path}/${userManualId}`)}>
                    <Box display="flex" className={classes.content}>
                        <Box className={classes.icon}>
                            <Box className={classes.iconInner}> 
                                <PdfIcon />
                            </Box>
                        </Box>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.name}>{userManual.name}</Box>
                            <Box className={classes.brand}>{userManual.brand}</Box>
                        </Box>
                        <Box p={2} className={classes.container}>
                            <Box className={classes.counter}>{userManual.numberWarranties}</Box>
                            <Box className={classes.title}>Warranties</Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Grid>
    );
}