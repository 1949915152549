import { Grid, makeStyles } from "@material-ui/core";
import empty from "./../assets/illustration/empty.svg";
import error from "./../assets/illustration/error.svg";

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        height: "auto"
    },
    innerContainer: {
        margin: "0 auto",
        textAlign: "center"
    },
    image: {
        display: "block",
        position: "relative",
        margin: "0 auto",
        maxWidth: "360px",
        cursor: "pointer"
    },
}));

export function StatusModuleEmpty() {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.container}
        >
            <Grid item xs={12} className={classes.innerContainer}>
                <img
                    src={empty}
                    alt="Empty"
                    className={classes.image}
                />
            </Grid>
        </Grid>
    );
}

export function StatusModuleError() {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.container}
        >
            <Grid item xs={12} className={classes.innerContainer}>
                <img
                    src={error}
                    alt="Error"
                    className={classes.image}
                />
            </Grid>
        </Grid>
    );
}