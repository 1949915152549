import React from 'react';
import { useSelector } from 'react-redux';
import {
    selectWarrantyById,
} from '../../store/slice/warranties';
import { Box, Grid, Paper, makeStyles } from "@material-ui/core";
import { useHistory, useRouteMatch } from 'react-router';
import { palette_000, palette_600, palette_800, palette_danger, palette_info, palette_success, palette_warning } from '../../utils/Colors';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "0px",
        cursor: "pointer",
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    content: {
        alignItems: "center"
    },
    image: {
        maxWidth: 100,
        maxHeight: 100,
        boxShadow: "10px 0px 9px -5px rgb(206 206 206 / 50%)",
        '& img': {
            height: 100
        }
    },
    container: {
        paddingRight: "116px",
        textAlign: "center"
    },
    status: {
        padding: "0px 5px",
        fontSize: "14px",
        textTransform: "uppercase"
    },
    wid: {
        color: palette_600,
        padding: "5px 5px",
        fontSize: "20px",
        fontWeight: 600
    },
    psn: {
        color: palette_800,
        padding: "0px 5px",
        fontSize: "16px"
    },
}));

const status = {
    "-1": "Expired",
    "0": "Pending",
    "1": "Activated",
    "2": "Issued"
}

const color = {
    "-1": palette_danger,
    "0": palette_warning,
    "1": palette_success,
    "2": palette_info
}

export const WarrantyCard = ({ warrantyId }) => {
    let history = useHistory();
    let { path } = useRouteMatch();

    const classes = useStyles();
    const warranty = useSelector((state) => selectWarrantyById(state, warrantyId));

    return (
        <Grid item xs={12}>
            <Box p={1}>
                <Paper
                    elevation={3}
                    className={classes.paper}
                    style={{
                        borderRight: `5px solid ${color[warranty.status.toString()]}`,
                    }}
                    onClick={() => history.push(`${path}/${warrantyId}`)}
                >
                    <Box display="flex" className={classes.content}>
                        <Box p={1} className={classes.image}><img src={warranty.image} alt="Product" /></Box>
                        <Box flexGrow={1} p={1} className={classes.container}>
                            <Box 
                                className={classes.status} 
                                style={{ color: color[warranty.status.toString()] }}
                            >
                                {status[warranty.status.toString()]}
                            </Box>
                            <Box className={classes.wid}>{warranty.id}</Box>
                            <Box className={classes.psn}>{warranty.productSerialNumber}</Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Grid>
    );
}