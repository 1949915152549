import { useState } from 'react'

export const useForm = (initialValues, validate) => {

    const [mode, setMode] = useState(false);
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const toggleMode = () => {
        setMode(!mode);
    };

    const setInputChange = (name, value) => {
        setValues({
            ...values,
            [name]: value
        });

        if (validate !== undefined) validate({ [name]: value });
    };

    const setMultipleInputChanges = (changes) => {
        setValues({
            ...values,
            ...changes,
        });

        if (validate !== undefined) validate(...changes);
    };

    const handleInputChange = e => {
        const name = e.target.name;
        const value = e.target.value;

        setValues({
            ...values,
            [name]: value
        });

        if (validate !== undefined) validate({ [name]: value });
    };

    const resetForm = () => {
        setValues(initialValues);
        setErrors({});
        setMode(false);
    }

    return {
        mode,
        values,
        errors,
        toggleMode,
        setInputChange,
        setMultipleInputChanges,
        handleInputChange,
        resetForm
    };
}