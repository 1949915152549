import React from 'react';
import { Grid } from '@material-ui/core';
import { Switch, Route, useRouteMatch } from 'react-router';
import Header from '../component/navigation/Header';
import WarrantiesView from '../component/warranty/WarrantiesView';
import WarrantyCreate from '../component/warranty/WarrantyCreate';
import WarrantyPage from '../component/warranty/WarrantyPage';

function WarrantyRoute() {

    let { path } = useRouteMatch();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Header name={"Warranties"} />
            <Switch>
                <Route exact path={path}>
                    <WarrantiesView />
                </Route>
                <Route path={`${path}/create`}>
                    <WarrantyCreate />
                </Route>
                <Route path={`${path}/:warrantyId`}>
                    <WarrantyPage />
                </Route>
            </Switch>
        </Grid>
    );
}

export default WarrantyRoute;