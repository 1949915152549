import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: "100%",
    },
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%"
    }
}));

export default function FormSelect(props) {
    const classes = useStyles();
    const { label, name, value, error = null, onChange, objectKey, data, ...other } = props;

    const children = [{ id: 0, [objectKey]: "None" }, ...data];

    if (label !== null) {
        return (
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id={`${name}_label`}>{label}</InputLabel>
                <Select
                    key={name}
                    id={name}
                    labelId={`${name}_label`}
                    label={label}
                    name={name}
                    value={value}
                    children={children.map(function (option, i) {
                        return <MenuItem key={i} value={option.id}>{option[objectKey]}</MenuItem>
                    })}
                    onChange={onChange}
                    {...(error && { error: true, helperText: error })}
                    {...other}
                    className={classes.field}
                />
            </FormControl>
        );
    } else {
        return (
            <Select
                key={name}
                id={name}
                variant="outlined"
                name={name}
                value={value}
                children={children.map(function (option, i) {
                    return <MenuItem key={i} value={option.id}>{option[objectKey]}</MenuItem>
                })}
                onChange={onChange}
                {...(error && { error: true, helperText: error })}
                {...other}
                className={classes.field}
            />
        );
    }
}