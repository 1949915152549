import React from 'react';
import { useSelector } from 'react-redux';
import { selectProductById } from '../../store/slice/products';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core';
import ProductPageInit from './ProductPageInit';
import Error404 from '../../Error404';
import { palette_000, palette_600, palette_800 } from '../../utils/Colors';

const useStyles = makeStyles((theme) => ({
    imagePaper: {
        maxWidth: "120px",
        maxHeight: "120px",
        margin: "0 auto",
        padding: "10px 0px",
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    image: {
        maxWidth: "100px",
        maxHeight: "100px",
        margin: "0 auto",
        padding: "0px"
    },
    paper: {
        margin: "0 auto",
        padding: "0px",
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    title: {
        padding: "5px 5px",
        color: palette_600,
        fontSize: "20px",
        fontWeight: 600
    },
    subtitle: {
        padding: "0px 5px 5px 5px",
        color: palette_800,
        fontSize: "16px"
    },
    divider: {
        padding: "0px 5px"
    },
    tooltip: {
        margin: "0px 5px"
    },
}));

const ProductPage = () => {
    let { productId } = useParams();

    const classes = useStyles();
    const product = useSelector((state) => selectProductById(state, productId));

    if (product !== undefined) {
        return (
            <ProductPageInit
                classes={classes}
                product={product}
            />
        );
    } else {
        return <Error404 />;
    }
}

export default ProductPage;