import React from 'react';
import { Grid } from '@material-ui/core';
import { Switch, Route, useRouteMatch } from 'react-router';
import Header from '../component/navigation/Header';
import TermsConditionsView from '../component/terms_conditions/TermsConditionsView';
import TermsConditionsCreate from '../component/terms_conditions/TermsConditionsCreate';
import TermsConditionsPage from '../component/terms_conditions/TermsConditionsPage';

function TermsConditionsRoute() {

    let { path } = useRouteMatch();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Header name={"Terms & Conditions"} />
            <Switch>
                <Route exact path={path}>
                    <TermsConditionsView />
                </Route>
                <Route path={`${path}/create`}>
                    <TermsConditionsCreate />
                </Route>
                <Route path={`${path}/:termsConditionsId`}>
                    <TermsConditionsPage />
                </Route>
            </Switch>
        </Grid>
    );
}

export default TermsConditionsRoute;