import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateProduct,
    deleteProduct,
} from '../../store/slice/products';
import {
    selectUserManualById,
    selectAllUserManual,
} from '../../store/slice/userManual';
import {
    selectTermsConditionsById,
    selectAllTermsConditions,
} from '../../store/slice/termsConditions';
import { useHistory } from 'react-router';
import { routes } from '../../utils/Router';
import { useForm } from '../../hook/useForm';
import { unwrapResult } from '@reduxjs/toolkit';
import { requestStatus } from '../../utils/RequestEnum';
import { useSnackbar } from 'notistack';
import { Paper, Grid, Box, Button, Divider, withStyles } from '@material-ui/core';
import { EditOutlined, RestoreOutlined, DeleteOutline } from '@material-ui/icons';
import Form from '../form/Form';
import FormInput from '../form/FormInput';
import FormSelect from '../form/FormSelect';
import FormButton from '../form/FormButton';
import { palette_600, palette_danger } from '../../utils/Colors';

const EditButton = withStyles((theme) => ({
    root: {
        color: palette_600,
        borderColor: palette_600
    },
}))(Button);

const ResetButton = withStyles((theme) => ({
    root: {
        color: palette_600,
        borderColor: palette_600
    },
}))(Button);

const DeleteButton = withStyles((theme) => ({
    root: {
        color: palette_danger,
        borderColor: palette_danger
    },
}))(Button);

const ProductPageInit = (props) => {
    const classes = props.classes;
    const product = props.product;

    const userManuals = useSelector((state) => selectAllUserManual(state));
    const productUserManual = useSelector((state) => selectUserManualById(state, product.userManualId));
    const termsConditions = useSelector((state) => selectAllTermsConditions(state));
    const productTermsConditions = useSelector((state) => selectTermsConditionsById(state, product.defaultTermsConditionsId));

    const [status, setStatus] = useState(requestStatus.IDLE);

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();
    const history = useHistory();

    const {
        mode,
        values,
        errors,
        toggleMode,
        setInputChange,
        handleInputChange,
        resetForm
    } = useForm({
        image: product.image,
        model: product.model,
        brand: product.brand,
        specification: product.specification,
        modification: product.modification,
        description: product.description,
        userManualId: product.userManualId,
        defaultTermsConditionsId: product.defaultTermsConditionsId,
        defaultDurationMonths: product.defaultDurationMonths
    });

    const imageRef = useRef(null);
    const imageInputRef = useRef(null);

    const handleImage = e => {
        try {
            let reader = new FileReader();

            reader.onload = function (e) {
                setInputChange("image", e.target.result);
            };

            reader.readAsDataURL(e.target.files[0]);
        } catch (e) { 
            enqueueSnackbar(`Invalid`, { 
                variant: 'error',
            });
        }
    };

    const handleEdit = e => {
        e.preventDefault();
        toggleMode();
    };

    const handleReset = e => {
        e.preventDefault();
        resetForm();
    };

    const handleDelete = e => {
        e.preventDefault();
        dispatch(deleteProduct({ id: product.id }));
        history.push(routes.products.full);
    };

    const handleUpdate = async e => {
        e.preventDefault();
        try {
            setStatus(requestStatus.LOADING);
            const resultAction = await dispatch(updateProduct({ id: product.id, ...values }));
            unwrapResult(resultAction);
            enqueueSnackbar(`Welldone`, { 
                variant: 'success',
            });
            setStatus(requestStatus.SUCCEEDED);
            toggleMode();
        } catch (err) {
            enqueueSnackbar(`Invalid`, { 
                variant: 'error',
            });
            setStatus(requestStatus.FAILED);
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Form>
                <Grid item xs={12}><Box p={1} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper
                        elevation={3}
                        className={classes.imagePaper}
                        onClick={() => { if (mode) imageInputRef.current.click() }}
                        style={mode ? { cursor: "pointer" } : { cursor: "default" }}
                    >
                        <Box className={classes.image}>
                            <img ref={imageRef} src={values.image} alt="Product" height={100} />
                        </Box>
                    </Paper>
                    <input ref={imageInputRef} id="image" accept="image/*" type="file" onChange={handleImage} hidden />
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Box display="flex">
                        <Box flexGrow={1} p={1} />
                        <Box>
                            {
                                mode ? (
                                    <ResetButton
                                        variant="outlined"
                                        startIcon={<RestoreOutlined />}
                                        className={classes.tooltip}
                                        onClick={handleReset}
                                    >
                                        Reset
                                    </ResetButton>
                                ) : (
                                    <EditButton
                                        variant="outlined"
                                        startIcon={<EditOutlined />}
                                        className={classes.tooltip}
                                        onClick={handleEdit}
                                    >
                                        Edit
                                    </EditButton>
                                )
                            }
                            <DeleteButton
                                variant="outlined"
                                startIcon={<DeleteOutline />}
                                className={classes.delete}
                                onClick={handleDelete}
                                disabled={mode}
                            >
                                Delete
                            </DeleteButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormInput
                                            label="Model"
                                            name="model"
                                            value={values.model}
                                            error={errors.model}
                                            onChange={handleInputChange}
                                            required={true}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box className={classes.title}>{values.model}</Box>
                                        <Box className={classes.subtitle}> Model </Box>
                                    </>
                                )
                            }
                            <Box className={classes.divider}><Divider /></Box>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormInput
                                            label="Brand"
                                            name="brand"
                                            value={values.brand}
                                            error={errors.brand}
                                            onChange={handleInputChange}
                                            required={true}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box className={classes.title}>{values.brand}</Box>
                                        <Box className={classes.subtitle}> Brand </Box>
                                    </>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormInput
                                            label="Specification"
                                            name="specification"
                                            value={values.specification}
                                            error={errors.specification}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box className={classes.title}>{values.specification}</Box>
                                        <Box className={classes.subtitle}> Specification </Box>
                                    </>
                                )
                            }
                            <Box className={classes.divider}><Divider /></Box>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormInput
                                            label="Modification"
                                            name="modification"
                                            value={values.modification}
                                            error={errors.modification}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box className={classes.title}>{values.modification}</Box>
                                        <Box className={classes.subtitle}> Modification </Box>
                                    </>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormInput
                                            label="Description"
                                            name="description"
                                            value={values.description}
                                            error={errors.description}
                                            onChange={handleInputChange}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box className={classes.title}>{values.description}</Box>
                                        <Box className={classes.subtitle}> Description </Box>
                                    </>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormSelect
                                            label="User Manual"
                                            name="userManualId"
                                            value={values.userManualId}
                                            error={errors.userManualId}
                                            onChange={handleInputChange}
                                            objectKey={"name"}
                                            data={userManuals}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box
                                            className={classes.title}
                                            onClick={() => history.push(`${routes.userManual.full}/${values.userManualId}`)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {productUserManual.name}
                                        </Box>
                                        <Box className={classes.subtitle}> User Manual </Box>
                                    </>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormSelect
                                            label="Default Terms & Conditions"
                                            name="defaultTermsConditionsId"
                                            value={values.defaultTermsConditionsId}
                                            error={errors.defaultTermsConditionsId}
                                            onChange={handleInputChange}
                                            objectKey={"name"}
                                            data={termsConditions}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box
                                            className={classes.title}
                                            onClick={() => history.push(`${routes.termsConditions.full}/${values.defaultTermsConditionsId}`)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {productTermsConditions.name}
                                        </Box>
                                        <Box className={classes.subtitle}> Default Terms & Conditions </Box>
                                    </>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormInput
                                            type="number"
                                            label="Default Duration Months"
                                            name="defaultDurationMonths"
                                            value={values.defaultDurationMonths}
                                            error={errors.defaultDurationMonths}
                                            onChange={handleInputChange}
                                            required={true}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box className={classes.title}>{values.defaultDurationMonths}</Box>
                                        <Box className={classes.subtitle}> Default Duration Months </Box>
                                    </>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>{product.dateCreated}</Box>
                            <Box className={classes.subtitle}> Created At </Box>
                            <Box className={classes.divider}><Divider /></Box>
                            <Box className={classes.title}>{product.dateUpdated}</Box>
                            <Box className={classes.subtitle}> Update At </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                {
                    mode && (
                        <>
                            <Grid item xs={12}>
                                <FormButton
                                    status={status}
                                    label="Update"
                                    onSubmit={handleUpdate}
                                />
                            </Grid>
                            <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                        </>
                    )
                }
            </Form>
        </Grid>
    );
}

ProductPageInit.propTypes = {
    classes: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired
};

export default ProductPageInit;