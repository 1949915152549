import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Grid } from "@material-ui/core";
import { LoaderModuleBox } from '../component/LoaderModule';
import { StatusModuleEmpty, StatusModuleError } from '../component/StatusModule';

const ListView = (props) => {
  const dispatch = useDispatch();

  const itemsIds = useSelector((state) => props.selector(state, props.settings));

  const status = useSelector((state) => state[props.slice].status);
  const error = useSelector((state) => state[props.slice].error);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (status === 'idle') {
      dispatch(props.fetch);
    }
  }, [status, dispatch, props.fetch]);

  let content;

  if (status === 'loading') {
    content = <LoaderModuleBox />;
  } else if (status === 'succeeded') {
    if (itemsIds.length !== 0) {
      content = itemsIds.map((id) => props.itemCard(id));
    } else {
      content = <StatusModuleEmpty />;
    }
  } else if (status === 'error') {
    enqueueSnackbar(error, {
      variant: 'error',
    });
    content = <StatusModuleError />;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {content}
    </Grid>
  );
};

ListView.propTypes = {
  slice: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  selector: PropTypes.func.isRequired,
  itemCard: PropTypes.func.isRequired
};

export default ListView;