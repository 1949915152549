import React from 'react';
import { useSelector } from 'react-redux';
import { selectWarrantyById } from '../../store/slice/warranties';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import WarrantyPageInitEditable from './WarrantyPageInitEditable';
import WarrantyPageInitUneditable from './WarrantyPageInitUneditable';
import Error404 from '../../Error404';
import { palette_000, palette_600, palette_800 } from '../../utils/Colors';

const useStyles = makeStyles((theme) => ({
    iconPaper: {
        display: "flex",
        margin: "0 auto",
        alignItems: "center",
        width: 110,
        height: 110,
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    icon: {
        margin: "0 auto",
        width: 70,
        height: 70,
        fill: palette_600
    },
    imagePaper: {
        maxWidth: "120px",
        maxHeight: "120px",
        margin: "0 auto",
        padding: "10px 0px",
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    image: {
        maxWidth: "100px",
        maxHeight: "100px",
        margin: "0 auto",
        padding: "0px"
    },
    paper: {
        margin: "0 auto",
        padding: "0px",
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    title: {
        padding: "5px 5px",
        color: palette_600,
        fontSize: "20px",
        fontWeight: 600
    },
    subtitle: {
        padding: "0px 5px 5px 5px",
        color: palette_800,
        fontSize: "16px"
    },
    divider: {
        padding: "0px 5px"
    },
    tooltip: {
        margin: "5px 5px"
    },
}));

const WarrantyPage = () => {
    let { warrantyId } = useParams();

    const classes = useStyles();
    const warranty = useSelector((state) => selectWarrantyById(state, warrantyId));

    if (warranty !== undefined) {
        if (parseInt(warranty.status) === -1 || parseInt(warranty.status) === 0) {
            return <WarrantyPageInitEditable classes={classes} warranty={warranty} />;
        } else {
            return <WarrantyPageInitUneditable classes={classes} warranty={warranty} />;
        }
    } else {
        return <Error404 />;
    }
}

export default WarrantyPage;