import React, { useState, forwardRef, useImperativeHandle  } from 'react';
import PropTypes from 'prop-types';
import { TextField, MenuItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    field: {
        paddingLeft: "10px",
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '10ch',
            border: '0'
        },
        '& .MuiSelect-select:focus': {
            background: "none"
        },
        '& .MuiInput-underline:after': {
            display: "none"
        },
        '& .MuiInput-underline:before': {
            display: "none"
        }
    }
}));

const SearchBoxSelect = forwardRef((props, ref) => {
    const classes = useStyles();
    const [value, setValue] = useState(props.options[0].value);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    useImperativeHandle(
        ref,
        () => ({
            value() {
                return value;
            }
        })
    );

    return (
        <TextField
            select
            value={value}
            onChange={handleChange}
            className={classes.field}
            inputProps={{ 'aria-label': 'select value' }}
        >
            {props.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
});

SearchBoxSelect.propTypes = {
    options: PropTypes.array.isRequired
};

export default SearchBoxSelect;