import React from "react";
import { routes } from "../../utils/Router";
import { Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectWarrantiesIdsByFilter } from '../../store/slice/warranties';
import store from "../../store/store";
import CardCounter from "./CardCounter";

function Dashboard() {
    let history = useHistory();

    let redirect = (path) => {
        history.push(path);
    };

    const activeWarrantiesLength = useSelector(state => selectWarrantiesIdsByFilter(state, {filter: "ACTIVATED"})).length;

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Typography variant="h3" style={{padding: "20px 0px"}}>Dashboard</Typography>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <CardCounter
                    title={"Products"}
                    count={store.getState().products.ids.length} 
                    grid={{
                        xs: 12, 
                        sm: 6,
                        xl: 3
                    }}
                    redirect={() => redirect(routes.products.full)}
                />
                <CardCounter
                    title={"Warranties"}
                    count={store.getState().warranties.ids.length} 
                    grid={{
                        xs: 12, 
                        sm: 6,
                        xl: 3
                    }}
                    redirect={() => redirect(routes.warranties.full)}
                />
                <CardCounter
                    title={"Terms & Conditions"}
                    count={store.getState().termsConditions.ids.length} 
                    grid={{
                        xs: 12, 
                        sm: 6,
                        xl: 3
                    }}
                    redirect={() => redirect(routes.termsConditions.full)}
                />
                <CardCounter
                    title={"User Manual"}
                    count={store.getState().userManual.ids.length} 
                    grid={{
                        xs: 12, 
                        sm: 6,
                        xl: 3
                    }}
                    redirect={() => redirect(routes.userManual.full)}
                />
                <CardCounter
                    title={"Active Warranties"}
                    count={activeWarrantiesLength} 
                    grid={{
                        xs: 12
                    }}
                    textAlign={"center"}
                />
            </Grid>
        </Grid>
    );
}

export default Dashboard;