import React from 'react';
import { Grid } from '@material-ui/core';
import { Switch, Route, useRouteMatch } from 'react-router';
import Header from '../component/navigation/Header';
import UserManualsView from '../component/user_manual/UserManualsView';
import UserManualCreate from '../component/user_manual/UserManualCreate';
import UserManualPage from '../component/user_manual/UserManualPage';

function UserManualRoute() {

    let { path } = useRouteMatch();

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Header name={"User Manual"} />
            <Switch>
                <Route exact path={path}>
                    <UserManualsView />
                </Route>
                <Route path={`${path}/create`}>
                    <UserManualCreate />
                </Route>
                <Route path={`${path}/:userManualId`}>
                    <UserManualPage />
                </Route>
            </Switch>
        </Grid>
    );
}

export default UserManualRoute;