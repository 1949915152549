import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createTermsConditions } from '../../store/slice/termsConditions';
import { useHistory } from 'react-router';
import { routes } from '../../utils/Router';
import { useForm } from '../../hook/useForm';
import { unwrapResult } from '@reduxjs/toolkit';
import { requestStatus } from '../../utils/RequestEnum';
import { useSnackbar } from 'notistack';
import { Paper, Grid, Box, Divider, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Form from '../form/Form';
import FormInput from '../form/FormInput';
import FormButton from '../form/FormButton';
import PdfIcon from '../../assets/icon/PdfIcon';
import { palette_000, palette_600, palette_800 } from '../../utils/Colors';

const useStyles = makeStyles((theme) => ({
    iconPaper: {
        display: "flex",
        margin: "0 auto",
        alignItems: "center",
        width: 110,
        height: 110,
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    icon: {
        margin: "0 auto",
        width: 70,
        height: 70,
        fill: palette_600
    },
    paper: {
        margin: "0 auto",
        padding: "0px",
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    title: {
        padding: "5px 5px",
        color: palette_600,
        fontSize: "20px",
        fontWeight: 600
    },
    subtitle: {
        padding: "0px 5px 5px 5px",
        color: palette_800,
        fontSize: "16px"
    },
    divider: {
        padding: "0px 5px"
    },
    tooltip: {
        margin: "0px 5px"
    },
}));

const FileAlert = (props) => {
    return <Alert elevation={6} variant="filled" {...props} />;
}

const TermsConditionsCreate = () => {
    const classes = useStyles();

    const [status, setStatus] = useState(requestStatus.IDLE);

    const dispatch = useDispatch();
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const {
        values,
        errors,
        setInputChange,
        handleInputChange
    } = useForm({
        name: "",
        brand: "",
        file: ""
    });

    const fileInputRef = useRef(null);

    const handleFile = e => {
        e.preventDefault();
        try {
            let reader = new FileReader();

            reader.onload = function (e) {
                setInputChange("file", e.target.result);
            };

            reader.readAsDataURL(e.target.files[0]);
        } catch (e) {
            enqueueSnackbar(`Invalid`, { 
                variant: 'error',
            });
        }
    };

    const handleCreate = async e => {
        e.preventDefault();
        try {
            setStatus(requestStatus.LOADING);
            const resultAction = await dispatch(createTermsConditions({ ...values }));
            unwrapResult(resultAction);
            enqueueSnackbar(`Welldone`, { 
                variant: 'success',
            });
            setStatus(requestStatus.SUCCEEDED);
            history.push(routes.termsConditions.full);
        } catch (err) {
            enqueueSnackbar(`Invalid`, { 
                variant: 'error',
            });
            setStatus(requestStatus.FAILED);
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Form>
                <Grid item xs={12}><Box p={1} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper
                        elevation={3}
                        className={classes.iconPaper}
                        onClick={() => fileInputRef.current.click()}
                        style={{ cursor: "pointer" }}
                    >
                        <Box className={classes.icon}>
                            <PdfIcon />
                        </Box>
                    </Paper>
                    <input ref={fileInputRef} id="pdf" accept="application/pdf" type="file" onChange={handleFile} hidden />
                </Grid>
                <Grid item xs={12}><Box p={1} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <FileAlert severity="info">Click the PDF Icon to add a file</FileAlert>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>
                                <FormInput
                                    label="Name"
                                    name="name"
                                    value={values.name}
                                    error={errors.name}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                            </Box>
                            <Box className={classes.divider}><Divider /></Box>
                            <Box className={classes.title}>
                                <FormInput
                                    label="Brand"
                                    name="brand"
                                    value={values.brand}
                                    error={errors.brand}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <FormButton
                        status={status}
                        label="Create"
                        onSubmit={handleCreate}
                    />
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
            </Form>
        </Grid>
    );
}

export default TermsConditionsCreate;