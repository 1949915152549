import React from 'react';
import { FormControl, FormControlLabel, Checkbox, withStyles } from '@material-ui/core';
import { palette_500, palette_600 } from '../../utils/Colors';

const CustomeCheckbox = withStyles({
    root: {
        color: palette_500,
        '&$checked': {
            color: palette_600,
        },
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

export default function FormCheckbox(props) {
    const { label, name, value, error = null, onChange, ...other } = props;
    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    });
    return (
        <FormControl
            key={name}
            id={name}
            {...(error && { error: true, helperText: error })}
        >
            <FormControlLabel
                label={label}
                control={
                    <CustomeCheckbox
                        name={name}
                        checked={value}
                        onChange={e => onChange(convertToDefEventPara(name, e.target.checked))}
                    />
                }
                {...other}
            />
        </FormControl>
    );
}