import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Box, InputBase, IconButton, makeStyles } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    input: {
        width: "100%"
    }
}));

const SearchBoxInput = forwardRef((props, ref) => {
    const classes = useStyles();
    const [value, setValue] = useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
        props.onChange(event.target.value);
    };

    useImperativeHandle(
        ref,
        () => ({
            value() {
                return value;
            }
        })
    );

    return (
        <>
            <Box p={1} flexGrow={1}>
                <InputBase
                    value={value}
                    onChange={handleChange}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                    className={classes.input}
                />
            </Box>
            <Box p={1}>
                {
                    value.length > 3 ? (
                        <IconButton aria-label="search icon" onClick={(e) => {
                            e.preventDefault();
                            setValue('');
                            props.onChange('');
                        }}>
                            <Close />
                        </IconButton>
                    ) : (
                        <IconButton aria-label="search icon">
                            <Search />
                        </IconButton>
                    )
                }
            </Box>
        </>
    );
});

SearchBoxInput.propTypes = {
    onChange: PropTypes.func.isRequired
};

export default SearchBoxInput;