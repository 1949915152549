export const routes = {
  client: {
    full: "/client"
  },
  dashboard: {
    partial: "/dashboard",
    full: "/client/dashboard"
  },
  products: {
    partial: "/products",
    full: "/client/products"
  },
  warranties: {
    partial: "/warranties",
    full: "/client/warranties"
  },
  termsConditions: {
    partial: "/termsConditions",
    full: "/client/termsConditions"
  },
  userManual: {
    partial: "/userManual",
    full: "/client/userManual"
  },
  auth: {
    full: "/auth"
  },
  signIn: {
    partial: "/signIn",
    full: "/auth/signIn"
  },
  signUp: {
    partial: "/signUp",
    full: "/auth/signUp"
  },
  forgotPassword: {
    partial: "/forgotPassword",
    full: "/auth/forgotPassword"
  },
  public: {
    full: "/public"
  }
};