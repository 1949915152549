import React, { useRef, useState } from 'react';
import {
    fetchWarranties,
    selectWarrantiesIdsByFilter,
} from '../../store/slice/warranties';
import { useHistory } from 'react-router';
import { routes } from '../../utils/Router';
import { Grid } from "@material-ui/core";
import SearchBox from '../../template/SearchBox';
import ControlButtons from '../../template/ControlButtons';
import ListView from '../../template/ListView';
import { WarrantyCard } from './WarrantyCard';

function WarrantiesView(props) {
    const search = useRef(null);
    const [settings, setSettings] = useState({ key: "ALL", value: "", filter: "DATE_DESC" });

    const history = useHistory();

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}> 
                <SearchBox 
                    ref={search}
                    options={[
                        {
                            value: 'WID',
                            label: 'WID'
                        },
                        {
                            value: 'PSN',
                            label: 'PSN'
                        }
                    ]}
                    filters={[
                        {
                            value: 'DATE_DESC',
                            label: 'Date DESC'
                        },
                        {
                            value: 'DATE_ASC',
                            label: 'Date ASC'
                        },
                        {
                            value: 'EXPIRED',
                            label: 'Expired'
                        },
                        {
                            value: 'PENDING',
                            label: 'Pending'
                        },
                        {
                            value: 'ACTIVATED',
                            label: 'Activated'
                        },
                        {
                            value: 'ISSUED',
                            label: 'Issued'
                        }
                    ]}
                    onValueChange={(value) => {
                        if (value.length > 1) {
                            setSettings({ ...settings, key: `FILTER_BY_${search.current.type()}`, value: value });
                        } else {
                            if (settings.key !== "ALL") {
                                setSettings({ ...settings, key: "ALL", value: '' });
                            }
                        }
                    }}
                    onFilterChange={(filter) => {
                        if (settings.filter !== filter ) setSettings({...settings, filter: filter});
                    }}
                /> 
            </Grid>
            <Grid item xs={12} style={{height: 20}} />
            <ControlButtons 
                onImport={(e) => history.push(routes.warranties.full + "/create")}
                onCreate={(e) => history.push(routes.warranties.full + "/create")}
            />
            <Grid item xs={12} style={{height: 20}} />
            <ListView
                slice={"warranties"}
                fetch={fetchWarranties}
                settings={settings}
                selector={selectWarrantiesIdsByFilter}
                itemCard={(id) => <WarrantyCard key={id} warrantyId={id} />}
            />
            <Grid item xs={12} style={{height: 40}} />
        </Grid>
    );
}

export default WarrantiesView;