import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createProduct } from '../../store/slice/products';
import { selectAllUserManual } from '../../store/slice/userManual';
import { selectAllTermsConditions } from '../../store/slice/termsConditions';
import { useHistory } from 'react-router';
import { routes } from '../../utils/Router';
import { useForm } from '../../hook/useForm';
import { unwrapResult } from '@reduxjs/toolkit';
import { requestStatus } from '../../utils/RequestEnum';
import { useSnackbar } from 'notistack';
import { Paper, Grid, Box, Divider, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Form from '../form/Form';
import FormInput from '../form/FormInput';
import FormSelect from '../form/FormSelect';
import FormButton from '../form/FormButton';
import ProductIcon from '../../assets/icon/ProductIcon';
import { palette_000, palette_600, palette_800 } from '../../utils/Colors';

const useStyles = makeStyles((theme) => ({
    iconPaper: {
        display: "flex",
        margin: "0 auto",
        alignItems: "center",
        width: 110,
        height: 110,
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    icon: {
        margin: "0 auto",
        width: 70,
        height: 70,
        fill: palette_600
    },
    imagePaper: {
        maxWidth: "120px",
        maxHeight: "120px",
        margin: "0 auto",
        padding: "10px 0px",
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    image: {
        maxWidth: "100px",
        maxHeight: "100px",
        margin: "0 auto",
        padding: "0px"
    },
    paper: {
        margin: "0 auto",
        padding: "0px",
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    title: {
        padding: "5px 5px",
        color: palette_600,
        fontSize: "20px",
        fontWeight: 600
    },
    subtitle: {
        padding: "0px 5px 5px 5px",
        color: palette_800,
        fontSize: "16px"
    },
    divider: {
        padding: "0px 5px"
    },
}));

const FileAlert = (props) => {
    return <Alert elevation={6} variant="filled" {...props} />;
}

const ProductCreate = () => {
    const classes = useStyles();

    const userManuals = useSelector((state) => selectAllUserManual(state));
    const termsConditions = useSelector((state) => selectAllTermsConditions(state));

    const dispatch = useDispatch();
    const history = useHistory();

    const [status, setStatus] = useState(requestStatus.IDLE);

    const { enqueueSnackbar } = useSnackbar();

    const {
        values,
        errors,
        setInputChange,
        handleInputChange
    } = useForm({
        image: "",
        model: "",
        brand: "",
        specification: "",
        modification: "",
        description: "",
        userManualId: 0,
        defaultTermsConditionsId: 0,
        defaultDurationMonths: 0
    });

    const imageRef = useRef(null);
    const imageInputRef = useRef(null);

    const handleImage = e => {
        try {
            let reader = new FileReader();

            reader.onload = function (e) {
                setInputChange("image", e.target.result);
            };

            reader.readAsDataURL(e.target.files[0]);
        } catch (e) { 
            enqueueSnackbar(`Invalid`, { 
                variant: 'error',
            });
        }
    };

    const handleCreate = async e => {
        e.preventDefault();
        try {
            setStatus(requestStatus.LOADING);
            const resultAction = await dispatch(createProduct({ ...values }));
            unwrapResult(resultAction);
            enqueueSnackbar(`Welldone`, { 
                variant: 'success',
            });
            setStatus(requestStatus.SUCCEEDED);
            history.push(routes.products.full);
        } catch (err) {
            enqueueSnackbar(`Invalid`, { 
                variant: 'error',
            });
            setStatus(requestStatus.FAILED);
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Form>
                <Grid item xs={12}><Box p={1} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper
                        elevation={3}
                        className={values.image !== "" ? classes.imagePaper : classes.iconPaper}
                        onClick={() => imageInputRef.current.click()}
                        style={{ cursor: "pointer" }}
                    >
                        <Box className={values.image !== "" ? classes.image : classes.icon}>
                            {
                                values.image !== "" ? <img ref={imageRef} src={values.image} alt="Product" height={100} /> : <ProductIcon /> 
                            }
                        </Box>
                    </Paper>
                    <input ref={imageInputRef} id="image" accept="image/*" type="file" onChange={handleImage} hidden />
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <FileAlert severity="info">Click the Box Icon to add an image</FileAlert>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>
                                <FormInput
                                    label="Model"
                                    name="model"
                                    value={values.model}
                                    error={errors.model}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                            </Box>
                            <Box className={classes.divider}><Divider /></Box>
                            <Box className={classes.title}>
                                <FormInput
                                    label="Brand"
                                    name="brand"
                                    value={values.brand}
                                    error={errors.brand}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>
                                <FormInput
                                    label="Specification"
                                    name="specification"
                                    value={values.specification}
                                    error={errors.specification}
                                    onChange={handleInputChange}
                                />
                            </Box>
                            <Box className={classes.divider}><Divider /></Box>
                            <Box className={classes.title}>
                                <FormInput
                                    label="Modification"
                                    name="modification"
                                    value={values.modification}
                                    error={errors.modification}
                                    onChange={handleInputChange}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>
                                <FormInput
                                    label="Description"
                                    name="description"
                                    value={values.description}
                                    error={errors.description}
                                    onChange={handleInputChange}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>
                                <FormSelect
                                    label="User Manual *"
                                    name="userManualId"
                                    value={values.userManualId}
                                    error={errors.userManualId}
                                    onChange={handleInputChange}
                                    objectKey={"name"}
                                    data={userManuals}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>
                                <FormSelect
                                    label="Default Terms & Conditions *"
                                    name="defaultTermsConditionsId"
                                    value={values.defaultTermsConditionsId}
                                    error={errors.defaultTermsConditionsId}
                                    onChange={handleInputChange}
                                    objectKey={"name"}
                                    data={termsConditions}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>
                                <FormInput
                                    type="number"
                                    label="Default Duration Months"
                                    name="defaultDurationMonths"
                                    value={values.defaultDurationMonths}
                                    error={errors.defaultDurationMonths}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <FormButton
                        status={status}
                        label="Create"
                        onSubmit={handleCreate}
                    />
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
            </Form>
        </Grid>
    );
}

export default ProductCreate;