import React, { useState } from "react";
import { Paper, Grid, makeStyles, Typography, Container, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { routes } from "./../../utils/Router";
// import { useDispatch } from "react-redux";
// import { signIn } from "./../../store/slice/user";
import { useForm } from "../../hook/useForm";
// import { unwrapResult } from "@reduxjs/toolkit";
import { requestStatus } from "../../utils/RequestEnum";
import Form from "../form/Form";
import FormInput from "../form/FormInput";
import FormButton from "../form/FormButton";
import { palette_600, } from "./../../utils/Colors";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "56px 48px",
        boxShadow: "0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%)"
    },
    title: {
        textAlign: "left",
        width: "100%"
    },
    link: {
        textDecoration: "none"
    },
    linkText: {
        color: palette_600,
        cursor: "pointer",
        textAlign: "center"
    },
    signUp: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1)
    }
}));

const initialValues = {
    email: '',
    isValid: false
};

function ForgotPassword() {
    const classes = useStyles();
    // const dispatch = useDispatch();

    const [status] = useState(requestStatus.IDLE);

    const {
        values,
        errors,
        handleInputChange
    } = useForm(initialValues);

    const handleSubmit = async e => {
        e.preventDefault();
        // try {
        //     setStatus(requestStatus.LOADING);
        //     const resultAction = await dispatch(signIn(
        //         {
        //             "email": values.email,
        //             "password": values.password
        //         }
        //     ));
        //     unwrapResult(resultAction);
        // } catch (err) {
        //     console.error('Failed to save the post: ', err);
        //     setStatus(requestStatus.FAILED);
        // } finally { }
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} className={classes.paper}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h5" gutterBottom>
                            Reset your password
                        </Typography>
                    </Grid>
                    <Form>
                        <Grid item xs={12}>
                            <FormInput
                                label="Email"
                                type="email"
                                name="email"
                                value={values.email}
                                error={errors.email}
                                onChange={handleInputChange}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormButton
                                status={status}
                                label="Continue"
                                onSubmit={handleSubmit}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Link to={routes.signIn.full} className={classes.link}>
                                <Typography variant="button" display="block" className={classes.linkText}>
                                    Return to sign in
                                </Typography>
                            </Link>
                        </Grid>
                    </Form>
                </Grid>
            </Paper>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.signUp}
            >
                <Typography variant="body1">
                    Don't have an account?
                </Typography>
                <Box m={0.5} />
                <Link to={routes.signUp.full} className={classes.link}>
                    <Typography variant="button" display="block" className={classes.linkText}>
                        Sign Up
                    </Typography>
                </Link>
            </Grid>
        </Container>
    );
}

export default ForgotPassword;