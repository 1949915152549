import React from 'react';

const PdfIcon = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <path d="M494.479 138.557L364.04 3.018A9.853 9.853 0 00356.945 0h-194.41c-21.757 0-39.458 17.694-39.458 39.442v137.789H44.29c-16.278 0-29.521 13.239-29.521 29.513v147.744C14.769 370.761 28.012 384 44.29 384h78.787v88.627c0 21.71 17.701 39.373 39.458 39.373h295.238c21.757 0 39.458-17.653 39.458-39.351V145.385a9.85 9.85 0 00-2.752-6.828zM359.385 26.581l107.079 111.265H359.385V26.581zM44.29 364.308c-5.42 0-9.828-4.405-9.828-9.82V206.744c0-5.415 4.409-9.821 9.828-9.821h265.882c5.42 0 9.828 4.406 9.828 9.821v147.744c0 5.415-4.409 9.82-9.828 9.82H44.29zm433.248 108.341c0 10.84-8.867 19.659-19.766 19.659H162.535c-10.899 0-19.766-8.828-19.766-19.68V384h167.403c16.278 0 29.521-13.239 29.521-29.512V206.744c0-16.274-13.243-29.513-29.521-29.513H142.769V39.442c0-10.891 8.867-19.75 19.766-19.75h177.157v128c0 5.438 4.409 9.846 9.846 9.846h128v315.111z"></path>
            <path d="M132.481 249.894c-3.269-4.25-7.327-7.01-12.173-8.279-3.154-.846-9.923-1.269-20.308-1.269H72.596v84.577h17.077v-31.904h11.135c7.731 0 13.635-.404 17.712-1.212 3-.654 5.952-1.99 8.856-4.01 2.904-2.019 5.298-4.798 7.183-8.336 1.885-3.538 2.827-7.904 2.827-13.096-.001-6.731-1.636-12.221-4.905-16.471zm-14.625 23.279c-1.288 1.885-3.067 3.269-5.337 4.154s-6.769 1.327-13.5 1.327h-9.346v-24h8.25c6.154 0 10.25.192 12.288.577 2.769.5 5.058 1.75 6.865 3.75 1.808 2 2.712 4.539 2.712 7.615.001 2.5-.644 4.692-1.932 6.577zM219.481 263.452c-1.846-5.404-4.539-9.971-8.077-13.702s-7.789-6.327-12.75-7.789c-3.692-1.077-9.058-1.615-16.096-1.615h-31.212v84.577h32.135c6.308 0 11.346-.596 15.115-1.789 5.039-1.615 9.039-3.865 12-6.75 3.923-3.808 6.942-8.788 9.058-14.942 1.731-5.039 2.596-11.039 2.596-18 0-7.923-.923-14.586-2.769-19.99zm-16.616 34.731c-1.154 3.789-2.644 6.51-4.471 8.163-1.827 1.654-4.125 2.827-6.894 3.519-2.115.539-5.558.808-10.327.808h-12.75v-56.019h7.673c6.961 0 11.635.269 14.019.808 3.192.692 5.827 2.019 7.904 3.981 2.077 1.962 3.692 4.692 4.846 8.192s1.731 8.519 1.731 15.058c0 6.538-.577 11.701-1.731 15.49z"></path>
            <path d="M294.827 254.654L294.827 240.346 236.846 240.346 236.846 324.923 253.923 324.923 253.923 288.981 289.231 288.981 289.231 274.673 253.923 274.673 253.923 254.654z"></path>
        </svg>
    );
}

export default PdfIcon;