import React, { useState, useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Box,
} from "@material-ui/core";
import { routes } from "../../utils/Router";
import { Menu, ExitToApp } from "@material-ui/icons";
import { Link as RouterLink, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "../../store/slice/user";
import { palette_800 } from "../../utils/Colors";
import warrantyboxLogo from "./../../assets/logo/logo.svg";

const appbarData = [
  {
    label: "Dashboard",
    href: routes.dashboard.full,
  },
  {
    label: "Products",
    href: routes.products.full,
  },
  {
    label: "Warranties",
    href: routes.warranties.full,
  },
  {
    label: "Terms & Conditions",
    href: routes.termsConditions.full,
  },
  {
    label: "User Manual",
    href: routes.userManual.full,
  },
];

const useStyles = makeStyles(() => ({
  appbar: {
    maxHeight: "64px",
    backgroundColor: palette_800,
    paddingRight: "79px",
    paddingLeft: "118px",
    "@media (max-width: 1250px)": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  logo: {
    maxWidth: "54px",
    cursor: "pointer",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 500,
    size: "16px",
    marginLeft: "38px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

export default function Appbar() {
  const drawer = useRef(null);

  const { appbar, logo, menuButton, toolbar, drawerContainer } = useStyles();

  const dispatch = useDispatch();

  let history = useHistory();

  let { path } = useRouteMatch();

  const [state, setState] = useState({
    mobileView: window.innerWidth < 1250 ? true : false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1250
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false, drawerOpen: false }));
    };

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <img src={warrantyboxLogo} alt="Warrantybox Logo" className={logo} onClick={() => history.push(routes.dashboard.full)} />
        <div>
          {getMenuButtons()}
          <Button
            {...{
              key: 'Sign Out',
              color: "inherit",
              className: menuButton,
              onClick: () => dispatch(signOut()),
            }}
          >
            Sign Out
          </Button>
        </div>
      </Toolbar>
    );
  };

  const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));

  const displayMobile = () => {
    return (
      <Toolbar>
        <Box flexShrink={0}>
          <IconButton
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <Menu />
          </IconButton>
        </Box>
        <Box width="100%" style={{ textAlign: "center" }}>
          <img src={warrantyboxLogo} alt="Warrantybox Logo" className={logo} style={{ marginLeft: "36px" }} onClick={() => history.push(routes.dashboard.full)} />
        </Box>
        <Box flexShrink={0}>
          <IconButton
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: () => dispatch(signOut()),
            }}
          >
            <ExitToApp />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return appbarData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            from: path,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  const getMenuButtons = () => {
    return appbarData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: "inherit",
            from: path,
            to: href,
            component: RouterLink,
            className: menuButton,
          }}
        >
          {label}
        </Button>
      );
    });
  };

  return (
    <div>
      <AppBar className={appbar}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
      <Drawer
        ref={drawer}
        {...{
          variant: "temporary",
          anchor: "left",
          open: drawerOpen,
          onClose: handleDrawerClose,
        }}
      >
        <div className={drawerContainer}>
          {getDrawerChoices()}
        </div>
      </Drawer>
    </div>
  );
}