import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectProductById } from '../../store/slice/products';
import { selectUserManualById } from '../../store/slice/userManual';
import { selectTermsConditionsById } from '../../store/slice/termsConditions';
import { useHistory } from 'react-router';
import { routes } from '../../utils/Router';
import { Paper, Grid, Box, Divider } from '@material-ui/core';
import ProductIcon from '../../assets/icon/ProductIcon';
import { palette_danger, palette_info, palette_success, palette_warning } from '../../utils/Colors';

const status = {
    "-1": "Expired",
    "0": "Pending",
    "1": "Activated",
    "2": "Issued"
}

const color = {
    "-1": palette_danger,
    "0": palette_warning,
    "1": palette_success,
    "2": palette_info
}

const WarrantyPageInitUneditable = (props) => {
    const classes = props.classes;
    const warranty = props.warranty;

    const warrantyProduct = useSelector((state) => selectProductById(state, warranty.productId));
    const warrantyUserManual = useSelector((state) => selectUserManualById(state, warranty.termsConditionsId));
    const warrantyTermsConditions = useSelector((state) => selectTermsConditionsById(state, warranty.userManualId));

    const history = useHistory();

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}><Box p={1} /></Grid> {/* Divider */}
            <Grid item xs={12}>
                <Paper
                    elevation={3}
                    className={warranty.image !== "" ? classes.imagePaper : classes.iconPaper}
                >
                    <Box className={warranty.image !== "" ? classes.image : classes.icon}>
                        {
                            warranty.image !== "" ? <img src={warranty.image} alt="Product" height={100} /> : <ProductIcon />
                        }
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
            <Grid item xs={12}>
                <Paper elevation={3} className={classes.paper}>
                    <Box flexGrow={1} p={1}>
                        <Box className={classes.title}>{warranty.id}</Box>
                        <Box className={classes.subtitle}> WID </Box>
                        <Box className={classes.divider}><Divider /></Box>
                        <Box className={classes.title}>{warranty.productSerialNumber}</Box>
                        <Box className={classes.subtitle}> PSN </Box>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
            <Grid item xs={12}>
                <Paper elevation={3} className={classes.paper}>
                    <Box flexGrow={1} p={1}>
                        <Box
                            className={classes.title}
                            onClick={() => history.push(`${routes.products.full}/${warranty.productId}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            {warrantyProduct.model}
                        </Box>
                        <Box className={classes.subtitle}> Product </Box>
                        <Box className={classes.divider}><Divider /></Box>
                        <Box
                            className={classes.title}
                            onClick={() => history.push(`${routes.userManual.full}/${warranty.userManualId}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            {warrantyUserManual.name}
                        </Box>
                        <Box className={classes.subtitle}> User Manual </Box>
                        <Box className={classes.divider}><Divider /></Box>
                        <Box
                            className={classes.title}
                            onClick={() => history.push(`${routes.termsConditions.full}/${warranty.termsConditionsId}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            {warrantyTermsConditions.name}
                        </Box>
                        <Box className={classes.subtitle}> Terms & Conditions </Box>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
            <Grid item xs={12}>
                <Paper elevation={3} className={classes.paper}>
                    <Box flexGrow={1} p={1}>
                        <Box className={classes.title} style={{ color: color[warranty.status.toString()] }}>
                            {status[warranty.status.toString()]}
                        </Box>
                        <Box className={classes.subtitle}> Status </Box>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
            <Grid item xs={12}>
                <Paper elevation={3} className={classes.paper}>
                    <Box flexGrow={1} p={1}>
                        <Box className={classes.title}>{warranty.durationMonths}</Box>
                        <Box className={classes.subtitle}> Duration Months </Box>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
            <Grid item xs={12}>
                <Paper elevation={3} className={classes.paper}>
                    <Box flexGrow={1} p={1}>
                        {
                            warranty.activationDate !== "0000-00-00 00:00:00" && <>
                                <Box className={classes.title}>{warranty.activationDate}</Box>
                                <Box className={classes.subtitle}> Activation Date </Box>
                            </>
                        }
                        {
                            warranty.activationDate !== "0000-00-00 00:00:00" && warranty.expirationDate !== "0000-00-00 00:00:00" && <Box className={classes.divider}><Divider /></Box> // Divider
                        }
                        {
                            warranty.expirationDate !== "0000-00-00 00:00:00" && <>
                                <Box className={classes.title}>{warranty.expirationDate}</Box>
                                <Box className={classes.subtitle}> Expiration Date </Box>
                            </>
                        }
                        {
                            warranty.expirationDate !== "0000-00-00 00:00:00" && warranty.issueDate !== "0000-00-00 00:00:00" && <Box className={classes.divider}><Divider /></Box> // Divider
                        }
                        {
                            warranty.issueDate !== "0000-00-00 00:00:00" && <>
                                <Box className={classes.title}>{warranty.issueDate}</Box>
                                <Box className={classes.subtitle}> Issue Date </Box>
                            </>
                        }
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
            <Grid item xs={12}>
                <Paper elevation={3} className={classes.paper}>
                    <Box flexGrow={1} p={1}>
                        <Box className={classes.title}>{warranty.dateCreated}</Box>
                        <Box className={classes.subtitle}> Created At </Box>
                        <Box className={classes.divider}><Divider /></Box>
                        <Box className={classes.title}>{warranty.dateUpdated}</Box>
                        <Box className={classes.subtitle}> Update At </Box>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
        </Grid>
    );
}

WarrantyPageInitUneditable.propTypes = {
    classes: PropTypes.object.isRequired,
    warranty: PropTypes.object.isRequired
};

export default WarrantyPageInitUneditable;