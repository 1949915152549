import React from 'react';
import { requestStatus } from '../../utils/RequestEnum';
import { Button, makeStyles, withStyles } from '@material-ui/core';
import FormProgress from './FormProgress';
import { palette_500, palette_600 } from '../../utils/Colors';

const useStyles = makeStyles((theme) => ({
    submit: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%"
    }
}));

const CustomeButton = withStyles((theme) => ({
    root: {
        padding: "14px 8px",
        color: theme.palette.getContrastText(palette_500),
        backgroundColor: palette_500,
        '&:hover': {
            backgroundColor: palette_600,
        }
    },
}))(Button);

export default function FormButton(props) {
    const classes = useStyles();
    const { status = requestStatus.IDLE, label, onSubmit, ...other } = props;

    switch (status) {
        case status === requestStatus.IDLE:
            return (
                <CustomeButton
                    variant="contained"
                    onClick={onSubmit}
                    className={classes.submit}
                    {...other}
                >
                    {label}
                </CustomeButton>
            );
        case status === requestStatus.LOADING:
            return (
                <CustomeButton
                    variant="contained"
                    onClick={onSubmit}
                    disabled={true}
                    className={classes.submit}
                    {...other}
                >
                    <FormProgress />
                </CustomeButton>
            );
        case status === requestStatus.SUCCEEDED:
            return (
                <CustomeButton
                    variant="contained"
                    onClick={onSubmit}
                    className={classes.submit}
                    {...other}
                >
                    {label}
                </CustomeButton>
            );
        case status === requestStatus.FAILED:
            return (
                <CustomeButton
                    variant="contained"
                    onClick={onSubmit}
                    className={classes.submit}
                    {...other}
                >
                    {label}
                </CustomeButton>
            );
        default:
            return (
                <CustomeButton
                    variant="contained"
                    onClick={onSubmit}
                    className={classes.submit}
                    {...other}
                >
                    {label}
                </CustomeButton>
            );
    }
}