import React, { useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "./../../utils/Router";
import { useDispatch } from "react-redux";
import { signIn } from "./../../store/slice/user";
import { useForm } from "../../hook/useForm";
import { unwrapResult } from "@reduxjs/toolkit";
import { requestStatus } from "../../utils/RequestEnum";
import { useSnackbar } from 'notistack';
import { Paper, Grid, makeStyles, Typography, Container, Box } from "@material-ui/core";
import Form from "../form/Form";
import FormInput from "../form/FormInput";
import FormCheckbox from "../form/FormCheckbox";
import FormButton from "../form/FormButton";
import { palette_600, } from "./../../utils/Colors";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "56px 48px",
        boxShadow: "0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%)"
    },
    title: {
        textAlign: "left",
        width: "100%"
    },
    link: {
        textDecoration: "none"
    },
    linkText: {
        color: palette_600,
        cursor: "pointer",
        textAlign: "right"
    },
    signUp: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1)
    }
}));

const initialValues = {
    email: '',
    password: '',
    rememberMe: false,
    isValid: false
};

function SignIn() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [status, setStatus] = useState(requestStatus.IDLE);

    const { enqueueSnackbar } = useSnackbar();

    const {
        values,
        errors,
        handleInputChange
    } = useForm(initialValues);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            setStatus(requestStatus.LOADING);
            const resultAction = await dispatch(signIn(
                {
                    "email": values.email,
                    "password": values.password
                }
            ));
            unwrapResult(resultAction);
            enqueueSnackbar(`Welcome`, { 
                variant: 'success',
            });
            // setStatus(requestStatus.SUCCEEDED);
        } catch (err) {
            enqueueSnackbar(`Invalid`, { 
                variant: 'error',
            });
            setStatus(requestStatus.FAILED);
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} className={classes.paper}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h5" gutterBottom>
                            Sign in to your account
                        </Typography>
                    </Grid>
                    <Form>
                        <Grid item xs={12}>
                            <FormInput
                                label="Email"
                                type="email"
                                name="email"
                                value={values.email}
                                error={errors.email}
                                onChange={handleInputChange}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Link to={routes.forgotPassword.full} className={classes.link}>
                                <Typography variant="button" display="block" className={classes.linkText}>
                                    Forgot your password?
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                label="Password"
                                type="password"
                                name="password"
                                value={values.password}
                                error={errors.password}
                                onChange={handleInputChange}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCheckbox
                                label="Stay signed in for a week"
                                name="rememberMe"
                                value={values.rememberMe}
                                error={errors.rememberMe}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormButton
                                status={status}
                                label="Continue"
                                onSubmit={handleSubmit}
                            />
                        </Grid>
                    </Form>
                </Grid>
            </Paper>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.signUp}
            >
                <Typography variant="body1">
                    Don't have an account?
                </Typography>
                <Box m={0.5} />
                <Link to={routes.signUp.full} className={classes.link}>
                    <Typography variant="button" display="block" className={classes.linkText}>
                        Sign up
                    </Typography>
                </Link>
            </Grid>
        </Container>
    );
}

export default SignIn;