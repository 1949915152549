import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
} from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { requestStatus } from "./../../utils/RequestEnum";

const productsAdapter = createEntityAdapter({
    sortComparer: (a, b) => b.dateCreated.localeCompare(a.dateCreated),
});

const initialState = productsAdapter.getInitialState({
    status: requestStatus.IDLE,
    error: null,
});

export const fetchProducts = createAsyncThunk('/client/products/fetch', async () => {
    const response = await client.get('products');
    return response.objects;
});

export const createProduct = createAsyncThunk(
    '/client/products/create',
    async (data) => {
        const response = await client.post('products', { body: { ...data } });
        return response.object;
    }
);

export const updateProduct = createAsyncThunk(
    '/client/products/update',
    async ({ id, ...changes }) => {
        const response = await client.put(`products/${id}`, { body: { ...changes } });
        return response.object;
    }
);

export const deleteProduct = createAsyncThunk(
    '/client/products/delete',
    async ({ id }) => {
        await client.delete(`products/${id}`);
        return { id: id };
    }
);

const productsSlice = createSlice({
    name: 'products',
    initialState: initialState,
    reducers: {
        productCreated(state, action) {
            const { ...data } = action.payload;
            productsAdapter.upsertOne(state, data);
        },
        productUpdated(state, action) {
            const { id, ...changes } = action.payload;
            productsAdapter.updateOne(state, { id, changes });
        },
        productDeleted(state, action) {
            const { id } = action.payload;
            productsAdapter.removeOne(state, id);
        },
    },
    extraReducers: {
        // Fetch
        [fetchProducts.pending]: (state) => {
            state.status = requestStatus.LOADING;
        },
        [fetchProducts.fulfilled]: (state, action) => {
            state.status = requestStatus.SUCCEEDED;
            productsAdapter.upsertMany(state, action.payload);
        },
        [fetchProducts.rejected]: (state, action) => {
            state.status = requestStatus.FAILED;
            state.error = action.payload;
        },
        // Create
        [createProduct.fulfilled]: (state, action) => {
            productsSlice.caseReducers.productCreated(state, action);
        },
        [createProduct.rejected]: (state, action) => {
            state.error = action.payload;
        },
        // Update
        [updateProduct.fulfilled]: (state, action) => {
            productsSlice.caseReducers.productUpdated(state, action);
        },
        [updateProduct.rejected]: (state, action) => {
            state.error = action.payload;
        },
        // Delete
        [deleteProduct.fulfilled]: (state, action) => {
            productsSlice.caseReducers.productDeleted(state, action);
        },
        [deleteProduct.rejected]: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const { productCreated, productUpdated, productDeleted } = productsSlice.actions;

export default productsSlice;

export const {
    selectAll: selectAllProducts,
    selectById: selectProductById,
    selectIds: selectProductsIds,
} = productsAdapter.getSelectors((state) => state.products);

export const selectProductsIdsByFilter = createSelector(
    [selectAllProducts, (state, settings) => settings],
    (storage, settings) => {
        switch (settings.filter) {
            case 'DATE_DESC': {
                storage.sort((a, b) => b.dateCreated.localeCompare(a.dateCreated));
                return selectProductsIdsByFilterKeys(storage, settings);
            }
            case 'DATE_ASC': {
                storage.sort((a, b) => a.dateCreated.localeCompare(b.dateCreated));
                return selectProductsIdsByFilterKeys(storage, settings);
            }
            default: return storage.map(entity => entity.id);
        }
    }
);

const selectProductsIdsByFilterKeys = (storage, settings) => {
    switch (settings.key) {
        case 'ALL':
            return storage.map(entity => entity.id);
        case 'FILTER_BY_MODEL':
            return storage.filter((entity) => entity.model.toLowerCase().includes(settings.value.toLowerCase())).map(entity => entity.id);
        case 'FILTER_BY_BRAND':
            return storage.filter((entity) => entity.brand.toLowerCase().includes(settings.value.toLowerCase())).map(entity => entity.id);
        default:
            return storage.map(entity => entity.id);
    }
}

export const selectProductsBrands = createSelector(
    selectAllProducts,
    (storage) => {
        let result = new Set();
        for (let [, value] of storage) {
            result.add(value.brand);
        }
        return result;
    }
);
