import { localStorage } from "es-storage";

export const apiUrl = "https://api.warrantybox.io/";
export const apiVersion = "v1/";

export async function client(method, endpoint, { headers = {}, body, useAuthToken = true, useStaticToken = false, useDynamicToken = false, ...settings }) {
  const authToken = await localStorage.get('authToken');

  if (authToken && useAuthToken) {
    headers.authorization = `Bearer ${authToken}`;
  }

  const config = {
    method: method,
    headers: {
      'content-type': 'application/json',
      ...headers,
    },
    ...settings,
  }

  if (body) {
    config.body = JSON.stringify(body);
  }

  return fetch(apiUrl + apiVersion + endpoint, config)
    .then(async response => {
      if (response.status === 401) {
        localStorage.remove('authToken');
        window.location.assign(window.location);
        return;
      }
      
      if (response.ok) {
        return await response.json();
      } else {
        const errorMessage = await response.text();
        return Promise.reject(new Error(errorMessage));
      }
    });
}

client.get = function (endpoint, settings = {}) {
  return client('GET', endpoint, { ...settings });
}

client.post = function (endpoint, settings = {}) {
  return client('POST', endpoint, { ...settings });
}

client.put = function (endpoint, settings = {}) {
  return client('PUT', endpoint, { ...settings });
}

client.delete = function (endpoint, settings = {}) {
  return client('DELETE', endpoint, { ...settings });
}