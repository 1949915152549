import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
} from '@reduxjs/toolkit';
import { client } from '../../api/client';
import { requestStatus } from "./../../utils/RequestEnum";

const termsConditionsAdapter = createEntityAdapter({
    sortComparer: (a, b) => b.dateCreated.localeCompare(a.dateCreated),
});

const initialState = termsConditionsAdapter.getInitialState({
    status: requestStatus.IDLE,
    error: null,
});

export const fetchTermsConditions = createAsyncThunk('/client/termsConditions/fetch', async () => {
    const response = await client.get('termsConditions');
    return response.objects;
});

export const createTermsConditions = createAsyncThunk(
    '/client/termsConditions/create',
    async (data) => {
        const response = await client.post('termsConditions', { body: { ...data } });
        return response.object;
    }
);

export const updateTermsConditions = createAsyncThunk(
    '/client/termsConditions/update',
    async ({ id, ...changes }) => {
        const response = await client.put(`termsConditions/${id}`, { body: { ...changes } });
        return response.object;
    }
);

export const deleteTermsConditions = createAsyncThunk(
    '/client/termsConditions/delete',
    async ({ id }) => {
        await client.delete(`termsConditions/${id}`);
        return { id: id };
    }
);

const termsConditionsSlice = createSlice({
    name: 'termsConditions',
    initialState: initialState,
    reducers: {
        termsConditionsCreated(state, action) {
            const { ...data } = action.payload;
            termsConditionsAdapter.upsertOne(state, data);
        },
        termsConditionsUpdated(state, action) {
            const { id, ...changes } = action.payload;
            termsConditionsAdapter.updateOne(state, { id, changes });
        },
        termsConditionsDeleted(state, action) {
            const { id } = action.payload;
            termsConditionsAdapter.removeOne(state, id);
        },
    },
    extraReducers: {
        // Fetch
        [fetchTermsConditions.pending]: (state) => {
            state.status = requestStatus.LOADING;
        },
        [fetchTermsConditions.fulfilled]: (state, action) => {
            state.status = requestStatus.SUCCEEDED;
            termsConditionsAdapter.upsertMany(state, action.payload);
        },
        [fetchTermsConditions.rejected]: (state, action) => {
            state.status = requestStatus.FAILED;
            state.error = action.payload;
        },
        // Create
        [createTermsConditions.fulfilled]: (state, action) => {
            termsConditionsSlice.caseReducers.termsConditionsCreated(state, action);
        },
        [createTermsConditions.rejected]: (state, action) => {
            state.error = action.payload;
        },
        // Update
        [updateTermsConditions.fulfilled]: (state, action) => {
            termsConditionsSlice.caseReducers.termsConditionsUpdated(state, action);
        },
        [updateTermsConditions.rejected]: (state, action) => {
            state.error = action.payload;
        },
        // Delete
        [deleteTermsConditions.fulfilled]: (state, action) => {
            termsConditionsSlice.caseReducers.termsConditionsDeleted(state, action);
        },
        [deleteTermsConditions.rejected]: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const { termsConditionsCreated, termsConditionsUpdated, termsConditionsDeleted } = termsConditionsSlice.actions;

export default termsConditionsSlice;

export const {
    selectAll: selectAllTermsConditions,
    selectById: selectTermsConditionsById,
    selectIds: selectTermsConditionsIds,
} = termsConditionsAdapter.getSelectors((state) => state.termsConditions);

export const selectTermsConditionsByBrand = createSelector(
    [selectAllTermsConditions, (state, brand) => brand],
    (storage, brand) => storage.filter((entity) => entity.brand === brand)
);

export const selectTermsConditionsIdsByFilter = createSelector(
    [selectAllTermsConditions, (state, settings) => settings],
    (storage, settings) => {
        switch (settings.filter) {
            case 'DATE_DESC': {
                storage.sort((a, b) => b.dateCreated.localeCompare(a.dateCreated));
                return selectTermsConditionsIdsByFilterKeys(storage, settings);
            }
            case 'DATE_ASC': {
                storage.sort((a, b) => a.dateCreated.localeCompare(b.dateCreated));
                return selectTermsConditionsIdsByFilterKeys(storage, settings);
            }
            default: return storage.map(entity => entity.id);
        }
    }
);

const selectTermsConditionsIdsByFilterKeys = (storage, settings) => {
    switch (settings.key) {
        case 'ALL':
            return storage.map(entity => entity.id);
        case 'FILTER_BY_NAME':
            return storage.filter((entity) => entity.name.toLowerCase().includes(settings.value.toLowerCase())).map(entity => entity.id);
        case 'FILTER_BY_BRAND':
            return storage.filter((entity) => entity.brand.toLowerCase().includes(settings.value.toLowerCase())).map(entity => entity.id);
        default:
            return storage.map(entity => entity.id);
    }
}