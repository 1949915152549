import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, makeStyles, withStyles } from "@material-ui/core";
import { ImportExportOutlined, PlusOneOutlined } from '@material-ui/icons';
import { palette_600, palette_success } from '../utils/Colors';

const useStyles = makeStyles((theme) => ({
    tooltip: {
        margin: "0px 5px"
    },
}));

const ImportButton = withStyles((theme) => ({
    root: {
        color: palette_600,
        borderColor: palette_600
    },
}))(Button);

const CreateButton = withStyles((theme) => ({
    root: {
        color: palette_success,
        borderColor: palette_success
    },
}))(Button);

const ControlButtons = (props) => {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Box display="flex">
                <Box flexGrow={1} p={1} />
                <Box>
                    <ImportButton
                        variant="outlined"
                        startIcon={<ImportExportOutlined />}
                        className={classes.tooltip}
                        onClick={props.onImport}
                    >
                        Import
                    </ImportButton>
                    <CreateButton
                        variant="outlined"
                        startIcon={<PlusOneOutlined />}
                        className={classes.tooltip}
                        onClick={props.onCreate}
                    >
                        Create
                    </CreateButton>
                </Box>
            </Box>
        </Grid>
    );
};

ControlButtons.propTypes = {
    onImport: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired
};

export default ControlButtons;