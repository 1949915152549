import React from "react";
import {
    Switch,
    Route,
    useLocation,
    useRouteMatch,
    Redirect
} from "react-router-dom";
import SignIn from "./component/auth/SignIn";
import SignUp from "./component/auth/SignUp";
import ForgotPassword from "./component/auth/ForgotPassword";
import logo from './assets/logo/logo.svg';
import { Grid, makeStyles } from "@material-ui/core";
import { routes } from "./utils/Router";

const useStyles = makeStyles((theme) => ({
    brand: {
        padding: "40px",
        textAlign: "center",
        '& img': {
            width: 60,
        }
    }
}));

function Auth() {
    const classes = useStyles();

    let location = useLocation();
    let { path, url } = useRouteMatch();

    return (
        <div>
            <Grid item xs={12} className={classes.brand}>
                <img src={logo} alt="Warrantybox Logo" />
            </Grid>
            <Switch>
                <Route exact path={path}>
                    <Redirect to={{
                        pathname: `${url}${routes.signIn.partial}`,
                        state: { from: location }
                    }} />
                </Route>
                <Route path={`${path}${routes.signIn.partial}`}>
                    <SignIn />
                </Route>
                <Route path={`${path}${routes.signUp.partial}`}>
                    <SignUp />
                </Route>
                <Route path={`${path}${routes.forgotPassword.partial}`}>
                    <ForgotPassword />
                </Route>
                <Route path={`${path}/reset`}>
                    <Redirect to={{
                        pathname: `${url}${routes.forgotPassword.partial}`,
                        state: { from: location }
                    }} />
                </Route>
            </Switch>
        </div>
    );
}

export default Auth;