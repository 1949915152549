import React from "react";
import {
    makeStyles,
    IconButton,
    Typography,
    Box,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        alignItems: "center"
    },
    icon: {
        fontSize: 30,
    },
    content: {
        paddingRight: "52px",
        textAlign: "center"
    },
}));

export default function Header({ name }) {
    const classes = useStyles();

    let history = useHistory();

    return (
        <Box display="flex" className={classes.container}>
            <Box p={1} className={classes.icon}>
                <IconButton
                    {...{
                        edge: "start",
                        color: "inherit",
                        "aria-label": "back",
                        "aria-haspopup": "true",
                        onClick: () => history.goBack(),
                    }}
                >
                    <ArrowBack />
                </IconButton>
            </Box>
            <Box flexGrow={1} p={1} className={classes.content}>
                <Typography variant="h4">{name}</Typography>
            </Box>
        </Box>
    );
}