
import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { palette_500 } from '../../utils/Colors';

const useProgressStyle = makeStyles((theme) => ({
    root: {
        position: 'relative'
    },
    top: {
        color: palette_500,
        animationDuration: '550ms',
        position: 'absolute',
        left: 0
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    circle: {
        strokeLinecap: 'round'
    }
}));

export default function FormProgress(props) {
    const classes = useProgressStyle();

    return (
        <div className={classes.root}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={40}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={40}
                thickness={4}
                {...props}
            />
        </div>
    );
}
