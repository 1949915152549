import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../store/store';
import { updateWarranty } from '../../store/slice/warranties';
import { selectAllProducts, selectProductById } from '../../store/slice/products';
import { selectAllUserManual, selectUserManualById } from '../../store/slice/userManual';
import { selectAllTermsConditions, selectTermsConditionsById } from '../../store/slice/termsConditions';
import { useHistory } from 'react-router';
import { routes } from '../../utils/Router';
import { useForm } from '../../hook/useForm';
import { unwrapResult } from '@reduxjs/toolkit';
import { requestStatus } from '../../utils/RequestEnum';
import { useSnackbar } from 'notistack';
import { Paper, Grid, Box, Divider, Button, withStyles } from '@material-ui/core';
import { EditOutlined, RestoreOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import Form from '../form/Form';
import FormInput from '../form/FormInput';
import FormSelect from '../form/FormSelect';
import FormButton from '../form/FormButton';
import ProductIcon from '../../assets/icon/ProductIcon';
import { palette_600, palette_danger, palette_info, palette_success, palette_warning } from '../../utils/Colors';

const warrantyStatus = {
    "-1": "Expired",
    "0": "Pending",
    "1": "Activated",
    "2": "Issued"
}

const warrantyStatusColor = {
    "-1": palette_danger,
    "0": palette_warning,
    "1": palette_success,
    "2": palette_info
}

const EditButton = withStyles((theme) => ({
    root: {
        color: palette_600,
        borderColor: palette_600
    },
}))(Button);

const ResetButton = withStyles((theme) => ({
    root: {
        color: palette_600,
        borderColor: palette_600
    },
}))(Button);

const FileAlert = (props) => {
    return <Alert elevation={6} variant="filled" {...props} />;
}

const WarrantyPageInitEditable = (props) => {
    const classes = props.classes;
    const warranty = props.warranty;

    const products = useSelector((state) => selectAllProducts(state));
    const warrantyProduct = useSelector((state) => selectProductById(state, warranty.productId));
    const userManuals = useSelector((state) => selectAllUserManual(state));
    const warrantyUserManual = useSelector((state) => selectUserManualById(state, warranty.userManualId));
    const termsConditions = useSelector((state) => selectAllTermsConditions(state));
    const warrantyTermsConditions = useSelector((state) => selectTermsConditionsById(state, warranty.termsConditionsId));

    const [status, setStatus] = useState(requestStatus.IDLE);

    const dispatch = useDispatch();
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const {
        mode,
        values,
        errors,
        toggleMode,
        setMultipleInputChanges,
        handleInputChange,
        resetForm
    } = useForm({
        image: warranty.image,
        productId: warranty.productId,
        productSerialNumber: warranty.productSerialNumber,
        userManualId: warranty.userManualId,
        termsConditionsId: warranty.termsConditionsId,
        durationMonths: warranty.durationMonths
    });

    const handleEdit = e => {
        e.preventDefault();
        toggleMode();
    };

    const handleReset = e => {
        e.preventDefault();
        resetForm();
    };

    const handleProduct = async e => {
        e.preventDefault();
        setMultipleInputChanges({
            [e.target.name]: e.target.value,
            "image": store.getState().products.entities[e.target.value].image
        });
    }

    const handleUpdate = async e => {
        e.preventDefault();
        try {
            setStatus(requestStatus.LOADING);
            const resultAction = await dispatch(updateWarranty({ id: warranty.id, ...values }));
            unwrapResult(resultAction);
            enqueueSnackbar(`Welldone`, { 
                variant: 'success',
            });
            setStatus(requestStatus.SUCCEEDED);
            toggleMode();
        } catch (err) {
            enqueueSnackbar(`Invalid`, { 
                variant: 'error',
            });
            setStatus(requestStatus.FAILED);
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Form>
                <Grid item xs={12}><Box p={1} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper
                        elevation={3}
                        className={values.image !== "" ? classes.imagePaper : classes.iconPaper}
                    >
                        <Box className={values.image !== "" ? classes.image : classes.icon}>
                            {
                                values.image !== "" ? <img src={values.image} alt="Product" height={100} /> : <ProductIcon />
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <FileAlert severity="info">Select Product Id to add an image</FileAlert>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Box display="flex">
                        <Box flexGrow={1} p={1} />
                        <Box>
                            {
                                mode ? (
                                    <ResetButton
                                        variant="outlined"
                                        startIcon={<RestoreOutlined />}
                                        className={classes.tooltip}
                                        onClick={handleReset}
                                    >
                                        Reset
                                    </ResetButton>
                                ) : (
                                    <EditButton
                                        variant="outlined"
                                        startIcon={<EditOutlined />}
                                        className={classes.tooltip}
                                        onClick={handleEdit}
                                    >
                                        Edit
                                    </EditButton>
                                )
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>{warranty.id}</Box>
                            <Box className={classes.subtitle}> WID </Box>
                            <Box className={classes.divider}><Divider /></Box>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormInput
                                            label="PSN"
                                            name="productSerialNumber"
                                            value={values.productSerialNumber}
                                            error={errors.productSerialNumber}
                                            onChange={handleInputChange}
                                            required={true}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box className={classes.title}>{values.productSerialNumber}</Box>
                                        <Box className={classes.subtitle}> PSN </Box>
                                    </>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormSelect
                                            name="productId"
                                            value={values.productId}
                                            error={errors.productId}
                                            onChange={handleProduct}
                                            objectKey={"model"}
                                            data={products}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box
                                            className={classes.title}
                                            onClick={() => history.push(`${routes.products.full}/${warranty.productId}`)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {warrantyProduct.model}
                                        </Box>
                                        <Box className={classes.subtitle}> Product </Box>
                                    </>
                                )
                            }
                            <Box className={classes.divider}><Divider /></Box>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormSelect
                                            name="userManualId"
                                            value={values.userManualId}
                                            error={errors.userManualId}
                                            onChange={handleInputChange}
                                            objectKey={"name"}
                                            data={userManuals}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box
                                            className={classes.title}
                                            onClick={() => history.push(`${routes.userManual.full}/${values.userManualId}`)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {warrantyUserManual.name}
                                        </Box>
                                        <Box className={classes.subtitle}> User Manual </Box>
                                    </>
                                )
                            }
                            <Box className={classes.divider}><Divider /></Box>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormSelect
                                            name="termsConditionsId"
                                            value={values.termsConditionsId}
                                            error={errors.termsConditionsId}
                                            onChange={handleInputChange}
                                            objectKey={"name"}
                                            data={termsConditions}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box
                                            className={classes.title}
                                            onClick={() => history.push(`${routes.termsConditions.full}/${values.termsConditionsId}`)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {warrantyTermsConditions.name}
                                        </Box>
                                        <Box className={classes.subtitle}> Terms & Conditions </Box>
                                    </>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title} style={{ color: warrantyStatusColor[warranty.status.toString()] }}>
                                {warrantyStatus[warranty.status.toString()]}
                            </Box>
                            <Box className={classes.subtitle}> Status </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            {
                                mode ? (
                                    <Box className={classes.title}>
                                        <FormInput
                                            type="number"
                                            label="Duration Months"
                                            name="durationMonths"
                                            value={values.durationMonths}
                                            error={errors.durationMonths}
                                            onChange={handleInputChange}
                                            required={true}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box className={classes.title}>{values.durationMonths}</Box>
                                        <Box className={classes.subtitle}> Duration Months </Box>
                                    </>
                                )
                            }
                        </Box>
                    </Paper>
                </Grid>
                {
                    (warranty.activationDate !== "0000-00-00 00:00:00" || warranty.expirationDate !== "0000-00-00 00:00:00" || warranty.issueDate !== "0000-00-00 00:00:00") && <>
                        <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                        <Grid item xs={12}>
                            <Paper elevation={3} className={classes.paper}>
                                <Box flexGrow={1} p={1}>
                                    {
                                        warranty.activationDate !== "0000-00-00 00:00:00" && <>
                                            <Box className={classes.title}>{warranty.activationDate}</Box>
                                            <Box className={classes.subtitle}> Activation Date </Box>
                                        </>
                                    }
                                    {
                                        warranty.activationDate !== "0000-00-00 00:00:00" && warranty.expirationDate !== "0000-00-00 00:00:00" && <Box className={classes.divider}><Divider /></Box> // Divider
                                    }
                                    {
                                        warranty.expirationDate !== "0000-00-00 00:00:00" && <>
                                            <Box className={classes.title}>{warranty.expirationDate}</Box>
                                            <Box className={classes.subtitle}> Expiration Date </Box>
                                        </>
                                    }
                                    {
                                        warranty.expirationDate !== "0000-00-00 00:00:00" && warranty.issueDate !== "0000-00-00 00:00:00" && <Box className={classes.divider}><Divider /></Box> // Divider
                                    }
                                    {
                                        warranty.issueDate !== "0000-00-00 00:00:00" && <>
                                            <Box className={classes.title}>{warranty.issueDate}</Box>
                                            <Box className={classes.subtitle}> Issue Date </Box>
                                        </>
                                    }
                                </Box>
                            </Paper>
                        </Grid>
                    </>
                }
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                <Grid item xs={12}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box flexGrow={1} p={1}>
                            <Box className={classes.title}>{warranty.dateCreated}</Box>
                            <Box className={classes.subtitle}> Created At </Box>
                            <Box className={classes.divider}><Divider /></Box>
                            <Box className={classes.title}>{warranty.dateUpdated}</Box>
                            <Box className={classes.subtitle}> Update At </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                {
                    mode && (
                        <>
                            <Grid item xs={12}>
                                <FormButton
                                    status={status}
                                    label="Update"
                                    onSubmit={handleUpdate}
                                />
                            </Grid>
                            <Grid item xs={12}><Box p={2} /></Grid> {/* Divider */}
                        </>
                    )
                }
            </Form>
        </Grid>
    );
}

WarrantyPageInitEditable.propTypes = {
    classes: PropTypes.object.isRequired,
    warranty: PropTypes.object.isRequired
};

export default WarrantyPageInitEditable;