import React, { useRef, useState } from 'react';
import {
    fetchUserManual,
    selectUserManualIdsByFilter,
} from '../../store/slice/userManual';
import { Grid } from "@material-ui/core";
import SearchBox from '../../template/SearchBox';
import ControlButtons from '../../template/ControlButtons';
import ListView from '../../template/ListView';
import { UserManualCard } from './UserManualCard';
import { routes } from '../../utils/Router';
import { useHistory } from 'react-router-dom';

function UserManualsView(props) {
    const search = useRef(null);
    const [settings, setSettings] = useState({ key: "ALL", value: "", filter: "DATE_DESC" });

    const history = useHistory();

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                <SearchBox
                    ref={search}
                    options={[
                        {
                            value: 'NAME',
                            label: 'Name'
                        },
                        {
                            value: 'BRAND',
                            label: 'Brand'
                        }
                    ]}
                    filters={[
                        {
                            value: 'DATE_DESC',
                            label: 'Date DESC'
                        },
                        {
                            value: 'DATE_ASC',
                            label: 'Date ASC'
                        }
                    ]}
                    onValueChange={(value) => {
                        if (value.length > 3) {
                            setSettings({ ...settings, key: `FILTER_BY_${search.current.type()}`, value: value });
                        } else {
                            if (settings.key !== "ALL") {
                                setSettings({ ...settings, key: "ALL", value: '' });
                            }
                        }
                    }}
                    onFilterChange={(filter) => {
                        if (settings.filter !== filter) setSettings({ ...settings, filter: filter });
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{ height: 20 }} />
            <ControlButtons
                onImport={() => history.push(routes.userManual.full + "/create")}
                onCreate={() => history.push(routes.userManual.full + "/create")}
            />
            <Grid item xs={12} style={{ height: 20 }} />
            <ListView
                slice={"userManual"}
                fetch={fetchUserManual}
                settings={settings}
                selector={selectUserManualIdsByFilter}
                itemCard={(id) => <UserManualCard key={id} userManualId={id} />}
            />
            <Grid item xs={12} style={{ height: 40 }} />
        </Grid>
    );
}

export default UserManualsView;