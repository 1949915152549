export const palette_000 = "#FFFFFF";
export const palette_100 = "#F6F9FC";
export const palette_150 = "#A3B4C3";
export const palette_200 = "#80E9FF";
export const palette_300 = "#00D3FE";
export const palette_400 = "#8495EE";
export const palette_450 = "#8095FF";
export const palette_500 = "#7A73FF";
export const palette_550 = "#3EA7DE";
export const palette_600 = "#627AFE";
export const palette_700 = "#0C2E4E";
export const palette_800 = "#0A2540";
export const palette_900 = "#06182C";
export const palette_950 = "#111439";
export const palette_1000 = "#000000";

export const palette_info = "#2196F3";
export const palette_success = "#4CAF50";
export const palette_warning = "#FF9800";
export const palette_danger = "#F44336";