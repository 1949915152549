import React from "react";
import { Box, Grid, Paper, makeStyles } from "@material-ui/core";
import { palette_000, palette_600 } from "../../utils/Colors";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "20px",
        borderRadius: "12px",
        backgroundColor: palette_000
    },
    counter: {
        padding: "10px 0px",
        fontSize: "26px",
    },
    title: {
        margin: "0px 0px",
        color: palette_600,
        fontSize: "18px",
        fontWeight: 600,
        letterSpacing: "1.5px"
    },
}));

function CardCounter(props) {
    const classes = useStyles();

    return (
        <Grid item {...props.grid}>
            <Box p={2}>
                <Paper 
                    elevation={3} 
                    className={classes.paper} 
                    style={{ textAlign: props.textAlign ?? "left", cursor: props.redirect != null ? "pointer" : "normal" }} 
                    onClick={props.redirect}
                >
                    <Box className={classes.counter}>{props.count}</Box>
                    <p className={classes.title}>{props.title}</p>
                </Paper>
            </Box>
        </Grid>
    );
}

export default CardCounter;